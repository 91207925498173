<header class="header-background">

  <nav class="navbar header justify-content-between px-5">
    <div class="logo d-flex flex-colum">
      <a class="d-flex align-items-center justify-content-center" (click)="toLandingPage()">
        <img alt="logo-header" class="digiteq-logo" src="./assets/img/Digiteq_logo_RGB.png">
      </a>
    </div>
    <div class="align-center"><strong>{{heading}}</strong></div>
    <div class="d-flex">
      <div id="confidential" class="navbar-brand fw-bold">CONFIDENTIAL</div>
      <ng-container *ngIf="isAuthenticated">
        <button pButton pRipple type="button" icon="pi pi-question" class="p-button-rounded p-button-secondary p-button-text"
                (click)="showHelp()"></button>
        <button *ngIf="isAdmin" pButton pRipple type="button" icon="pi pi-briefcase" class="p-button-rounded p-button-secondary p-button-text"
                (click)="adminMenu.toggle($event)"></button>
        <button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-secondary p-button-text"
                (click)="userMenu.toggle($event)" ></button>
        <p-menu #userMenu [model]="userMenuItems" [popup]="true" appendTo="body"></p-menu>
        <p-menu #adminMenu [model]="adminMenuItems" [popup]="true" appendTo="body"></p-menu>
        <div class="ms-3 my-auto">{{ currentUser?.name }}</div>
      </ng-container>
    </div>
  </nav>

</header>
