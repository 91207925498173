import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';

export class FormUtils {
  public static checkIfFormGroupValid(form: FormGroup | undefined, messageService: MessageService): boolean {
    if (!form) {
      return false;
    }
    form.markAllAsTouched();
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].markAsDirty();
    });
    if (form.invalid) {
      messageService.add({
        severity: 'error',
        summary:'Form invalid',
        detail: 'Please provide valid values for all required fields.',
      });
    }
    return form.valid;
  }

  public static hasFormControlErrors(form: FormGroup | undefined, formControlName: string): boolean {
    if (!form) {
      return false;
    }
    return Boolean(form.controls[formControlName].dirty && form.controls[formControlName].touched && form.controls[formControlName].errors);
  }

}
