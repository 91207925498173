import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { HeaderComponent } from './components/header/header.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChatComponent } from './pages/chat/chat.component';
import { ApiModule, Configuration } from 'digiteq-ai-portal-client-lib';
import { environment } from '../environments/environment';
import { EditorModule } from 'primeng/editor';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarModule } from 'primeng/sidebar';
import { SettingsComponent } from './components/settings/chat-settings/settings.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { SliderModule } from 'primeng/slider';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { MarkdownModule } from 'ngx-markdown';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { HelpComponent } from './pages/help/help.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { StreamChatService } from "./services/stream-chat.service";
import { InputNumberModule } from 'primeng/inputnumber';
import { GenericChatComponent } from './pages/public-chat/generic-chat.component';
import { CompanyChatComponent } from './pages/company-chat/company-chat.component';
import { HistoryItemComponent } from './components/history/history-item/history-item.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LandingPageTileComponent } from './components/landing-page-tile/landing-page-tile.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { AssistantChatComponent } from './pages/assistant-chat/assistant-chat.component';
import { FileUploadModule } from 'primeng/fileupload';
import { BadgeModule } from 'primeng/badge';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChatAssistantFilesComponent } from './components/chat-assistant-files/chat-assistant-files.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AssistantSettingsComponent } from './components/settings/assistant-settings/assistant-settings.component';
import { SecurePipe } from './services/pipes/secure.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DaiseAdminComponent } from './pages/daise-admin/daise-admin.component';
import { NewDaiseIndexDialogComponent } from './components/new-daise-index-dialog/new-daise-index-dialog.component';
import {
  CompanyChatSettingsComponent
} from './components/settings/company-chat-settings/company-chat-settings.component';
import { ChipsModule } from 'primeng/chips';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactUsComponent,
    ChatComponent,
    SettingsComponent,
    FormFieldComponent,
    LandingPageComponent,
    HelpComponent,
    GenericChatComponent,
    CompanyChatComponent,
    AssistantChatComponent,
    HistoryItemComponent,
    LandingPageTileComponent,
    FileUploadComponent,
    ChatAssistantFilesComponent,
    AssistantSettingsComponent,
    SecurePipe,
    DaiseAdminComponent,
    NewDaiseIndexDialogComponent,
    CompanyChatSettingsComponent,
    FormErrorMessageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthConfigModule,
    FontAwesomeModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    MenuModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    SidebarModule,
    InputSwitchModule,
    DropdownModule,
    SliderModule,
    FileUploadModule,
    TabViewModule,
    BadgeModule,
    DynamicDialogModule,
    ApiModule.forRoot(() => new Configuration({
      basePath: environment.apiUrl
    })),
    EditorModule,
    RadioButtonModule,
    MarkdownModule.forRoot(),
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TableModule,
    ProgressBarModule,
    CheckboxModule,
    OverlayPanelModule,
    ChipsModule
  ],
  providers:
    [
      {
        provide: ErrorHandler,
        useClass: ApplicationinsightsAngularpluginErrorService,
      },
      MessageService,
      StreamChatService,
      ConfirmationService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
