import { Component } from '@angular/core';
import { FileUtils } from '../../../utils/file-utils/file-utils.component';
import { appConstants } from '../../../constants/app-constants';
import { AssistantService } from 'digiteq-ai-portal-client-lib';
import { ErrorService } from '../../../services/error.service';
import { AiAssistantService } from '../../../services/ai-assistant.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { FileModel, FileUploadEnum } from '../../../models/file-model';
import { MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-assistant-settings',
  templateUrl: './assistant-settings.component.html',
  styleUrl: './assistant-settings.component.scss'
})
export class AssistantSettingsComponent {
  uploading = false;
  newFiles: FileModel[] = [];
  files$?: BehaviorSubject<FileModel[]> = new BehaviorSubject([]);
  codeInterpreterFiles$?: BehaviorSubject<FileModel[]> = new BehaviorSubject([]);

  readonly maxNumberOfFiles = 20;
  private readonly maxTotalFilesSize = 512000000;
  filesSizeSum = 0;
  codeInterpreterFilesSizeSum = 0;


  readonly appConstants = appConstants;
  readonly fileUploadEnum = FileUploadEnum;

  get files() {
    return this.aiAssistantService.threadSearchFiles;
  }

  get codeInterpreterFiles() {
    return this.aiAssistantService.threadCodeInterpreterFiles;
  }

  constructor(
    private config: PrimeNGConfig,
    private assistantService: AssistantService,
    private errorService: ErrorService,
    private aiAssistantService: AiAssistantService,
    private messageService: MessageService,
  ) { }

  getFileIcon(filename: string | undefined): string {
    return FileUtils.getFileIcon(filename);
  }

  addFiles(files: File[],isCodeInterpreter: boolean) {
    this.newFiles = files.filter(f => !this.files.includes(f));
    this.newFiles.forEach(f => {
      f.uploaded = FileUploadEnum.NEW;
    })

    let filesSizeSum = files.reduce((acc, file) => acc + file.size, 0);
    if (filesSizeSum > this.maxTotalFilesSize) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'The total size of the files exceeds the limit of 512 MB.'
      })
      while(this.newFiles.length && filesSizeSum > this.maxTotalFilesSize) {
        files.pop();
        this.newFiles.pop();
        filesSizeSum = files.reduce((acc, file) => acc + file.size, 0)
      }
    }

    if (isCodeInterpreter) {
      this.codeInterpreterFilesSizeSum = filesSizeSum;
      this.aiAssistantService.threadCodeInterpreterFiles.push(...this.newFiles);
    } else {
      this.filesSizeSum = filesSizeSum;
      this.aiAssistantService.threadSearchFiles.push(...this.newFiles);
    }
  }

  uploadFiles(isCodeInterpreter: boolean) {
    let fileArray = [];
    let body = {
      searchFiles: null,
      interpreters: null,
    }
    if (isCodeInterpreter) {
      body.interpreters = this.newFiles ? Array.from(this.newFiles) : [];
      fileArray = this.codeInterpreterFiles || [];
    } else {
      body.searchFiles = this.newFiles ? Array.from(this.newFiles) : [];
      fileArray = this.files || [];
    }

    fileArray.forEach(f => {
      if (f.uploaded === FileUploadEnum.FAILED || f.uploaded === FileUploadEnum.NEW) {
        f.uploaded = FileUploadEnum.UPLOADING;
      }
    })

    this.uploading = true;

    this.assistantService.threadUploadFiles(this.aiAssistantService.threadId || 0, body.searchFiles, body.interpreters).pipe(
      finalize(() => {
        this.uploading = false
      })
    ).subscribe({
      next: (response) => {
        this.aiAssistantService.threadId = response.threadId;
        fileArray.forEach(f => {
          f.uploaded = FileUploadEnum.UPLOADED;
          const property = isCodeInterpreter ? 'interpreters' : 'searchFiles';
          f.id = response[property].find(file => file.name === f.name)?.id;
        })
        const fileText = fileArray.length > 1 ? 'Files' : 'File';
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${fileText} uploaded successfully`
        })
      },
      error: (error) => {
        this.errorService.handleError(error, 'Error uploading files');
      }
    });
  }

  deleteFile(isCodeInterpreter: boolean, file: FileModel, index: number) {
    const deleteFromTable = () => {
      if (isCodeInterpreter) {
        this.aiAssistantService.threadCodeInterpreterFiles.splice(index, 1);
        this.codeInterpreterFilesSizeSum -= file.size;
        this.codeInterpreterFiles$.next(this.codeInterpreterFiles);
      } else {
        this.aiAssistantService.threadSearchFiles.splice(index, 1);
        this.aiAssistantService.threadSearchFiles = [...this.aiAssistantService.threadSearchFiles]; 
        this.files$.next(this.files);
      }
    }

    if (file.uploaded === FileUploadEnum.UPLOADED) {
      this.assistantService.deleteAssistantFile(file.id).subscribe({
        next: () => {
          deleteFromTable();
        },
        error: (error) => {
          this.errorService.handleError(error, 'Error deleting file');
        }
      });
    } else {
      deleteFromTable();
    }

  }

  getUploadStatusIcon(file: FileModel): string {
    switch (file.uploaded) {
      case FileUploadEnum.UPLOADED:
        return 'pi pi-cloud';
      case FileUploadEnum.UPLOADING:
        return 'pi pi-spin pi-spinner';
      case FileUploadEnum.FAILED:
        return 'pi pi-times';
      default:
        return 'pi pi-cloud-upload';
    }
  }

  areAllFilesUploaded(isCodeInterpreter: boolean): boolean {
    const fileArray = isCodeInterpreter ? this.aiAssistantService.threadCodeInterpreterFiles : this.aiAssistantService.threadSearchFiles;
    return fileArray.every(f => f.uploaded === FileUploadEnum.UPLOADED);
  }

  formatSize(bytes: number): string {
    return FileUtils.formatSize(bytes, this.config.translation.fileSizeTypes);
  }

}
