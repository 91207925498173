export enum FileUploadEnum {
  NEW = 'new',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}

export class FileModel extends File {
  uploaded?: FileUploadEnum;
  id?: number;

  constructor(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag, uploaded: FileUploadEnum = FileUploadEnum.NEW, id?: number) {
    super(fileBits, fileName, options);
    this.uploaded = uploaded;
    this.id = id;
  }
}

