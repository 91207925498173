<p-fileUpload
  [name]="fileParameterName"
  [url]="uploadUrl"
  [auto]="autoUpload"
  [accept]="acceptedFileFormat"
  [multiple]="multiple"
  [maxFileSize]="MAX_FILE_SIZE"
  [customUpload]="true"
  (onUpload)="isUploading = false"
  (onSelect)="onSelectedFiles($event)"
  (onError)="isUploading = false"
  invalidFileTypeMessageSummary="Invalid file type"
  invalidFileTypeMessageDetail="Please select supported file type"
  [disabled]="disableFileSelection"
>

  <ng-template
    pTemplate="content"
    let-uploadedFiles="uploadedFiles"
    let-removeUploadedFileCallback="removeUploadedFileCallback"
    let-chooseCallback="chooseCallback">
    <div class="flex align-items-center justify-content-center flex-column drop-area p-6">
      <div class="d-flex">
        <p-button
          (onClick)="onChooseButtonClick(chooseCallback)"
          styleClass="upload-button"
          severity="secondary"
          [rounded]="true"
          icon="pi pi-upload"
          class="p-button-rounded m-auto"/>
      </div>

      <p class="text-center fs-4 m-3 mb-0">Choose a file or drag it here.</p>
    </div>
    <p-progressBar
      *ngIf="isUploading"
      [value]="totalSizePercent"
      [showValue]="false"
      [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }">
      <span class="white-space-nowrap">{{ totalSize }}B / {{ MAX_FILE_SIZE }}</span>
    </p-progressBar>
    <div *ngIf="showFiles && (uploadedFiles?.length > 0 || files)" class="pt-3">
      <p-table *ngIf="showFiles && files" [value]="files">
        <ng-template pTemplate="body" let-file>
          <tr>
            <td>
              @if (acceptedFileFormat === 'image/*') {
                <img role="presentation" alt="uploaded-image" [src]="getObjectUrl(file)" width="50" height="50"/>
              } @else {
                <i [class]="getFileIcon(file.name)" style="font-size: 1.25rem"></i>
              }
            </td>
            <td><span class="font-semibold text-truncate">{{ file?.name }}</span></td>
            <td><span>{{ formatSize(file?.size) }}</span></td>
            <td *ngIf="useFileName"><input type="text" pInputText [ngModel]="customNames.get(file)"
                                           (input)="customNameChanged(file, $event)"/></td>
            <td class="text-end">
              <p-button icon="pi pi-trash"
                        styleClass="width-fix"
                        (onClick)="onRemoveFile(file, removeUploadedFileCallback)"
                        [rounded]="true"
                        [outlined]="true"
                        severity="secondary"/>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>

  <ng-template pTemplate="file">
    <!-- Do not show the file while it is being loaded -->
  </ng-template>
</p-fileUpload>
