export class FileUtils {
  public static readonly PDF_EXTENSIONS = ['pdf'];
  public static readonly WORD_EXTENSIONS = ['doc', 'docx', 'odt', 'fodt', 'ott', 'sxw', 'stw'];
  public static readonly EXCEL_EXTENSIONS = ['xls', 'xlsx', 'csv', 'ods', 'fods', 'ots', 'sxc', 'stc'];
  public static readonly IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

  public static   formatSize(bytes: number, fileSizeTypes: string[] | undefined): string {
    const kiloConstant = 1024; //e.g. One megabyte is 1024 kilobytes
    const decimalPlaces = 3;

    if (!fileSizeTypes) {
      return '';
    }

    if (bytes === 0) {
      return `0 ${fileSizeTypes[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(kiloConstant));
    const formattedSize = parseFloat((bytes / Math.pow(kiloConstant, i)).toFixed(decimalPlaces));

    return `${formattedSize} ${fileSizeTypes[i]}`;
  }

  public static getFileIcon(filename: string | undefined): string {
    const extIndex = filename?.lastIndexOf('.');
    if (!extIndex) return 'pi pi-file';
    const extension = filename?.substring(extIndex + 1).toLowerCase();
    if (!extension) return 'pi pi-file';

    if (this.PDF_EXTENSIONS.includes(extension)) {
      return 'pi pi-file-pdf';
    } else if (this.WORD_EXTENSIONS.includes(extension)) {
      return 'pi pi-file-word';
    } else if (this.EXCEL_EXTENSIONS.includes(extension)) {
      return 'pi pi-file-excel';
    } else if (this.IMAGE_EXTENSIONS.includes(extension)) {
      return 'pi pi-image';
    } else {
      return 'pi pi-file';
    }
  }

  public static getAcceptedTypesFormatted(acceptedFiles: string): string {
    return acceptedFiles.replace(/,/g, ', ');
  }
}
