import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { IndexService } from 'digiteq-ai-portal-client-lib';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';

export const daiseGuard: CanActivateFn = () => {
  const indexService = inject(IndexService);
  const router: Router = inject(Router);
  const messageService = inject(MessageService)

  return indexService.getIndexes().pipe(
    map((indexes) => {
      if (indexes.length > 0) {
        return true;
      } else {
        messageService.add({
          severity: 'error',
          detail: 'You are not able to see required page.',
          summary: 'Access denied',
          life: 3000
        })
        router.navigate(['']).then(() => {});
        return false;
      }
    }),
    catchError(() => {
      router.navigate(['']).then(() => {});
      return of(false);
    })
  );
};
