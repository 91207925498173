import { Injectable, Optional } from '@angular/core';
import {
  ApiErrorResponseModel,
  AssistantRequestModel,
  AssistantResponseModel,
  ChatResponseModel,
  ChatService,
  CompanyChatRequestModel,
  CompanyChatResponseModel,
  Configuration,
  GenericChatRequestModel,
} from "digiteq-ai-portal-client-lib";
import { HttpClient } from "@angular/common/http";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { mergeMap, Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class StreamChatService extends ChatService {

  private previousStep: any;

  constructor(
    httpClient: HttpClient,
    @Optional() configuration: Configuration,
    private securityService: OidcSecurityService) {
    super(httpClient, null, configuration);
  }

  private fetchData<T>(url: string, token: string, options: RequestInit, isAssistantFetch: boolean = false): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      fetch(environment.apiUrl + url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': 'Bearer ' + token,
        },
      }).then(async response => {
        if (!response.ok) {
          response.json().then((error: ApiErrorResponseModel) => {
            observer.error(error);
          });
          return {
            unsubscribe() {
            },
          };
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        while (true) {
          const {done, value} = await reader.read();

          if (done) {
            observer.complete();
            break;
          }
          const text = decoder.decode(value);
          if (text.startsWith("ERROR")) {
            const error: ApiErrorResponseModel = JSON.parse(text.substring("ERROR: ".length));
            observer.error(error);
            return {
              unsubscribe() {
              },
            };
          }
          if (text.startsWith("STOP")) {
            observer.error("Stream stopped");
            return {
              unsubscribe() {
              },
            };
          }

          if (isAssistantFetch) {
            text
              .split(/#%!/) // #%! is there to easily separate messages
              .filter(s => s.trim() !== '' && s !== "{}")
              .map(json => JSON.parse(json))
              .filter(obj => obj !== undefined)
              .forEach((t) => {
                console.log(t);
                if (!t.text) {
                  t.text = '';
                }
                if (t.updateType === 'StepUpdate' && this.previousStep['updateType'] !== 'StepUpdate') {
                  t['text'] = '\n```';
                }
                this.previousStep = t;
                observer.next(t);
              });
          } else {
            text.split(/#%!/)
              .filter(s => s.trim() !== '' && s !== "{}")
              .map(json => JSON.parse(json))
              .forEach((t) => {
                observer.next(t);
              });
          }
        }
        return {
          unsubscribe() {
          },
        };
      }).catch(e => {
        console.error(e);
        observer.error("Response error");
      });
    });
  }

  private assistantFetch(model: AssistantRequestModel, token: string, searchFiles?: Array<File>, interpreters?: Array<File>): Observable<AssistantResponseModel> {
    const formData = new FormData();

    searchFiles.forEach((file) => {
      formData.append("searchFiles", file, file.name);
    });

    interpreters.forEach((file) => {
      formData.append("interpreters", file, file.name);
    });

    formData.append("assistantRequestModel", new Blob([JSON.stringify(model)], {type: "application/json"}));

    return this.fetchData<AssistantResponseModel>("/assistant/stream", token, {
      method: "post",
      body: formData,
    }, true);
  }

  private fetchChat<T>(model: CompanyChatRequestModel | GenericChatRequestModel, url: string, token: string): Observable<T> {
    return this.fetchData<T>(url, token, {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });
  }

  public realGenericChatStream(model: GenericChatRequestModel): Observable<ChatResponseModel> {
    return this.securityService.getAccessToken()
      .pipe(mergeMap(token => {
        return this.fetchChat<ChatResponseModel>(model, "/chat/generic/stream", token);
      }));
  }

  public realCompanyChatStream(model: CompanyChatRequestModel): Observable<CompanyChatResponseModel> {
    return this.securityService.getAccessToken()
      .pipe(mergeMap(token => {
        return this.fetchChat<CompanyChatResponseModel>(model, "/chat/company/stream", token);
      }));
  }

  public realAssistantChatStream(model: AssistantRequestModel, searchFiles?: Array<File>, interpreters?: Array<File>): Observable<AssistantResponseModel> {
    return this.securityService.getAccessToken()
      .pipe(mergeMap(token => {
        return this.assistantFetch(model, token, searchFiles, interpreters);
      }));
  }

}
