import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from '../../../services/settings.service';
import { ChatSettings, ChatSettingsRanges, OpenAIModelType, UserRole } from 'digiteq-ai-portal-client-lib';
import { AuthService } from '../../../services/auth.service';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorService } from '../../../services/error.service';
import { SettingsItems } from '../../../models/settings-items';
import { AiModelOption } from 'src/app/models/ai-model-option';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  private subscriptions: Subscription[] = [];

  private _stopSequence: string;
  get stopSequence(): string {
    return this._stopSequence;
  }

  set stopSequence(value: string) {
    this._stopSequence = value;
    this.form.controls['stopSequence'].setValue(value.split('|'))
  }

  tooltips = new Map<string, string>();
  form: FormGroup;
  isModelO1User = false;
  defaultSettings: ChatSettings;
  settingsRanges: ChatSettingsRanges;
  settingsItemsEnum = SettingsItems;
  aiModels: AiModelOption[] | undefined;
  selectedModel: AiModelOption | undefined;

  get hideSettings(): boolean {
    return this.selectedModel?.version == OpenAIModelType.O1 ||
      this.selectedModel?.version == OpenAIModelType.O3Mini;
  }

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.tooltips.set(SettingsItems.gptVersion, 'Choose version of OpenAI. Only users with role O1_MODEL can use o1 model.');
    this.tooltips.set(SettingsItems.prompt, 'Give the model instructions about how it should behave and any context it should reference ' +
      'when generating a response. You can describe the assistant’s personality, tell it what it should and shouldn’t answer,' +
      ' and tell it how to format responses. There’s no token limit for this section, but it will be included with every ' +
      'API call, so it counts against the overall token limit.');
    this.tooltips.set(SettingsItems.maxTokens, 'Set a limit on the number of tokens per model response. One token is roughly 4 characters ' +
      'for typical English text. Minimum 1.');
    this.tooltips.set(SettingsItems.temperature, 'Controls randomness. Lowering the temperature means that the model will produce ' +
      'more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. ' +
      'Try adjusting temperature or Top P but not both. Minimum 0, maximum 1.');
    this.tooltips.set(SettingsItems.nucleusSamplingFactor, 'Similar to temperature, this controls randomness but uses a different method. ' +
      'Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose ' +
      'from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both. Minimum 0, maximum 1.');
    this.tooltips.set(SettingsItems.stopSequence, 'Make the model end its response at a desired point. The model response will end ' +
      'before the specified sequence, so it won\'t contain the stop sequence text. For example, using "11." will prevent ' +
      'the model from generating numbered lists with more than 10 points. You can include as many as four stop sequences.');
    this.tooltips.set(SettingsItems.frequencyPenalty, 'Reduce the chance of repeating a token proportionally based on how often ' +
      'it has appeared in the text so far. This decreases the likelihood of repeating the exact same text in a response. ' +
      'Negative values increase the likelihood of repetition, positive values decrease it. Minimum -2, maximum 2');
    this.tooltips.set(SettingsItems.presencePenalty, 'Reduce the chance of repeating any token that has appeared in the text at all so far. ' +
      'This increases the likelihood of introducing new topics in a response. Negative values increase the likelihood of ' +
      'repetition, positive values decrease it. Minimum -2, maximum 2.');
    this.tooltips.set(SettingsItems.lastMessages, 'Set how many previous messages will be included in the model\'s context when ' +
      'generating a response. Minimum 1.');

    this.isModelO1User = this.authService.isInRoles([UserRole.O1Model]);

    this.aiModels = [
      {version: OpenAIModelType.Gpt35, displayName: 'GPT-3.5 Turbo', isDisabled: false},
      {version: OpenAIModelType.Gpt4, displayName: 'GPT-4o', isDisabled: false},
      {version: OpenAIModelType.Gpt4oMini, displayName: 'GPT-4o-mini', isDisabled: false},
      {version: OpenAIModelType.O1, displayName: 'o1', isDisabled: !this.isModelO1User},
      {version: OpenAIModelType.O3Mini, displayName: 'o3-mini', isDisabled: false},
    ];

    let settingsChangeSubscription = this.settingsService.$settingsChange.subscribe(settings => {
      this.form.setValue(settings);
      this.selectedModel = this.getGptOptionByVersion(settings.version);
    })
    this.subscriptions.push(settingsChangeSubscription);

    forkJoin([
      this.settingsService.getInitialSettings(),
      this.settingsService.getSettingsRanges(),
    ])
      .subscribe({
        next: ([initialSettings, settingsRanges]) => {
          this.settingsService.savedSettings = initialSettings;
          this.settingsRanges = settingsRanges;
          this.prepareForm();
        },
        error: err => {
          this.errorService.handleError(err, 'Could not get the chat settings.')
        }
      });
  }

  subscribeOnSliderValues() {
    const sliderFormControls = ['maxTokens', 'temperature', 'nucleusSamplingFactor', 'lastMessages', 'frequencyPenalty', 'presencePenalty'];
    sliderFormControls.forEach(sliderFormControlName => {
      let formControlSubscription = this.form.controls[sliderFormControlName].valueChanges.subscribe(value => {
        this.form.controls[sliderFormControlName].setValue(value, {onlySelf: true, emitEvent: false})
      })
      this.subscriptions.push(formControlSubscription);
    })
  }

  getGptOptionByVersion(gptVersion: OpenAIModelType): AiModelOption {
    return this.aiModels.find(x => x.version == gptVersion);
  }

  gptVersionChanged(event) {
    const gptVersionOption = this.getGptOptionByVersion(event.value.version);
    this.form.controls['version'].setValue(gptVersionOption.version);
    this.settingsService.gptVersion = gptVersionOption.version;
  }

  prepareForm() {
    this.form = this.fb.group(this.settingsService.savedSettings);
    this.selectedModel = this.getGptOptionByVersion(this.settingsService.savedSettings.version);
    this.form.controls['version'].setValue(this.selectedModel.version);
    this.settingsService.gptVersion = this.selectedModel.version;

    let currentSettingsSubscription = this.form.valueChanges.subscribe(currentSettings => {
      this.settingsService.savedSettings = currentSettings;
    })
    this.subscriptions.push(currentSettingsSubscription);
    this.subscribeOnSliderValues();
  }

  resetToDefaultSettings() {
    if (this.defaultSettings) {
      this.settingsService.savedSettings = this.defaultSettings;
      this.prepareForm();
      return;
    }
    this.settingsService.getDefaultSettings().subscribe({
      next: settings => {
        this.defaultSettings = settings;
        this.settingsService.savedSettings = settings;
        this.prepareForm();
      },
      error: err => {
        this.errorService.handleError(err, 'Could not reset the chat settings.')
      }
    });
  }

  getTooltip(field: string): string {
    return this.tooltips.get(field) || '';
  }

  ngOnDestroy(): void {
    if (this.subscriptions?.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }
}
