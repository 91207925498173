import { OpenAIModelType } from 'digiteq-ai-portal-client-lib';
import { Titled } from '../models/titled';

export const AiModelOptions: Titled[] = [
  {id: OpenAIModelType.Gpt35, title: 'GPT-3.5 Turbo'},
  {id: OpenAIModelType.Gpt4, title: 'GPT-4o'},
  {id: OpenAIModelType.Gpt4oMini, title: 'GPT-4o-mini'},
  {id: OpenAIModelType.O1, title: 'o1'},
  {id: OpenAIModelType.O3Mini, title: 'o3-mini'},
];
