import { Titled } from '../models/titled';
import { QueryType } from 'digiteq-ai-portal-client-lib';

export const QueryTypeOptions: Titled[] = [
  { title: 'Semantic', id: QueryType.Semantic },
  { title: 'Simple', id: QueryType.Simple },
  { title: 'Vector', id: QueryType.Vector },
  { title: 'Vector Semantic Hybrid', id: QueryType.VectorSemanticHybrid },
  { title: 'Vector Simple Hybrid', id: QueryType.VectorSimpleHybrid }
];
