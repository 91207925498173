import { Component, Input, OnInit } from '@angular/core';
import { IndexAdminModel } from 'digiteq-ai-portal-client-lib';
import { CompanyChatService } from '../../../company-chat.service';

@Component({
  selector: 'app-company-chat-settings',
  templateUrl: './company-chat-settings.component.html',
  styleUrl: './company-chat-settings.component.scss'
})
export class CompanyChatSettingsComponent implements OnInit{
  @Input() availableIndexes: IndexAdminModel[] = [];


  private _index?: IndexAdminModel;
  get index(): IndexAdminModel {
    return this._index;
  }

  set index(value: IndexAdminModel) {
    this._index = value;
    this.daiseService.daiseIndex = value;
  }

  constructor(
    private daiseService: CompanyChatService,
  ) { }

  ngOnInit(): void {
    this._index = this.daiseService.daiseIndex;
  }
}
