import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IndexAdminModel, IndexService } from 'digiteq-ai-portal-client-lib';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';
import { ErrorService } from '../../services/error.service';
import { finalize } from 'rxjs';
import { AiModelOptions } from '../../constants/AiModelOptions';
import { QueryTypeOptions } from '../../constants/QueryTypeOptions';

interface Column {
  title: string;
  field: string;
  template?: any;
}

@Component({
  selector: 'app-daise-admin',
  templateUrl: './daise-admin.component.html',
  styleUrl: './daise-admin.component.scss'
})
export class DaiseAdminComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') table?: Table;
  @ViewChild('modelTemplate') modelTemplate: TemplateRef<HTMLTemplateElement>;
  @ViewChild('queryTypeTemplate') queryTypeTemplate: TemplateRef<HTMLTemplateElement>;

  item?: IndexAdminModel;
  items?: IndexAdminModel[];
  loading = true;
  clonedItem: IndexAdminModel;
  deleteItemDialog: boolean = false
  search = '';
  showIndexDialog = false;

  columns: Column[] = []

  constructor(
    private indexService: IndexService,
    private messageService: MessageService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.indexService.getIndexesAdmin()
      .pipe(
        finalize(() => this.loading = false)
      )
     .subscribe({
      next: (result) => {
        this.items = result;
      },
      error: err => {
        this.errorService.handleError(err,'Could not load indexes');
      }
    })
  }

  ngAfterViewInit() {
    this.columns = [
      {field: 'name', title: 'Name'},
      {field: 'model', title: 'Model', template: this.modelTemplate},
      {field: 'role', title: 'Role'},
      {field: 'lastMessages', title: 'Last Messages'},
      {field: 'temperature', title: 'Temperature'},
      {field: 'documentCount', title: 'Document Count'},
      {field: 'queryType', title: 'Query Type', template: this.queryTypeTemplate},
      {field: 'prompt', title: 'Prompt'},
    ];
  }

  onGlobalFilter(event: Event) {
    this.table?.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clearSearch() {
    (document.getElementById('search') as HTMLInputElement).value = '';
    this.table?.filterGlobal('', 'contains');
    this.search = '';
  }

  editIndex(item: IndexAdminModel) {
    if (item.id === undefined || item.id === null) {
      return;
    }
    this.clonedItem = structuredClone(item)
    this.showIndexDialog = true;
  }

  deleteIndex(item: IndexAdminModel) {
    this.deleteItemDialog = true;
    this.item = item;
  }

  onDeleteConfirm() {
    this.deleteItemDialog = false;
    this.handleDelete(this.item.id);
    this.item = undefined;
  }

  addIndex() {
    this.showIndexDialog = true;
    this.clearSearch();
  }

  handleDelete(itemId: number): void {
    this.indexService.deleteIndex(itemId).subscribe({
      next: () => {
        const itemIndex = this.items?.findIndex((i) => i.id === itemId);
        if (itemIndex >= 0) {
          this.items?.splice(itemIndex, 1);
        }
        this.showDeleteMessage('index');
      },
      error: (error: HttpErrorResponse) => {
        this.showFailMessage(error, 'index', 'delete');
      },
    });
  }

  handleEdit(editData: IndexAdminModel): void {
    let itemIndex = this.items.findIndex((i) => i.id === editData.id);
    if (typeof itemIndex === 'number') {
      this.items[itemIndex] = editData;
    }
    this.items = [...this.items];
  }

  handleAdd(newItem: IndexAdminModel): void {
    this.items.push(newItem)
  }

  handleToggle(editedItem: IndexAdminModel): void {
    const toggle = () => {
      if (editedItem) {
        editedItem.enabled = !editedItem.enabled
      }
    }

    this.indexService.updateIndex(editedItem.id, {enabled: !editedItem.enabled}).subscribe({
      next: () => {
        this.showToggleMessage(editedItem.enabled, 'Index');
        toggle();
      },
      error: (error: HttpErrorResponse) => {
        this.showFailMessage(error, 'Index', 'update');
        toggle();
      },
    });
  }

  private showDeleteMessage(itemType: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: `${itemType} deleted.`,
    });
  }

  private showFailMessage(httpErrorResponse: HttpErrorResponse, itemType: string, operationType: 'add' | 'delete' | 'update' | 'upload') {
    this.errorService.handleError(httpErrorResponse,`Could not ${operationType} the ${itemType.toLowerCase()}.`);
  }

  private showToggleMessage(enabled: boolean | undefined, itemType: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: `${itemType} ${enabled ? 'enabled' : 'disabled'}.`,
    });
  }

  onIndexDialogCancel() {
    this.showIndexDialog = false;
    this.clonedItem = null;
  }

  getModelTitle(item: IndexAdminModel) {
    return AiModelOptions.find((model) => model.id === item.model)?.title;
  }

  getQueryTypeTitle(item: IndexAdminModel) {
    return QueryTypeOptions.find((queryType) => queryType.id === item.queryType)?.title;
  }
}
