<div class="m-5 mt-0">
  <h3>DAISE Indexes</h3>
  <p-table
    #dt
    [value]="items"
    dataKey="id"
    [loading]="loading"
    responsiveLayout="scroll"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="caption">
      <div class="d-flex flex-wrap gap-2 align-items-center justify-content-between">
        <div>
          <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
            <i class="pi pi-search"></i>
            <input id="search" pInputText type="text" (input)="onGlobalFilter($event)" placeholder="Search"
                   class="w-full" [(ngModel)]="search"/>
          </span>
          <p-button [text]="true" [severity]="'secondary'" icon="pi pi-filter-slash" (click)="clearSearch()"/>
        </div>
          <p-button
            (click)="addIndex()"
            pRipple
            [rounded]="true"
            [outlined]="true"
            class="p-button-text"
            icon="pi pi-plus"
            label="Add new"
          ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        @for (col of columns; track col.title) {
          <th  scope="col" [pSortableColumn]="col.field" class="white-space-nowrap">
            <span>{{ col.title }}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        }
        <th scope="col">Enabled</th>
        <th scope="col" class="text-center" style="width: 10rem" >Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td *ngIf="item.default" class="default" [attr.colspan]="columns.length + 2">DEFAULT</td>
      </tr>
      <tr [ngClass]="{'default-index': item.default}">
        @for (col of columns; track col.title) {
          <td>
            @if (col.template) {
              <ng-container *ngTemplateOutlet="col.template; context: {item: item}"></ng-container>
            } @else {
              <span>{{ item[col.field] }}</span>
            }
          </td>
        }
        <td style="text-align: center">
          <p-inputSwitch (onChange)="handleToggle(item)" [(ngModel)]="item.enabled" [disabled]="item.id === 0"></p-inputSwitch>
        </td>
        <td style="text-align: center">
          <div>
            <p-button
              (click)="editIndex(item)"
              [rounded]="true"
              [text]="true"
              severity="secondary"
              icon="pi pi-pencil"
              pRipple
            />
            <p-button
              (click)="deleteIndex(item)"
              [rounded]="true"
              [text]="true"
              severity="secondary"
              icon="pi pi-trash"
              pRipple
            />
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="3" class="text-center">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #modelTemplate  let-item="item">
  <div>{{getModelTitle(item)}}</div>
</ng-template>

<ng-template #queryTypeTemplate  let-item="item">
  <div>{{getQueryTypeTitle(item)}}</div>
</ng-template>

<p-dialog [(visible)]="showIndexDialog" header="Add index" [style]="{ width: '65%' }" [modal]="true" (onHide)="onIndexDialogCancel()">
  <app-new-daise-index-dialog *ngIf="showIndexDialog"
    [editedItem]="clonedItem"
    (onNewIndexCreated)="handleAdd($event)"
    (onIndexEdited)="handleEdit($event)"
    (onIndexCancel)="onIndexDialogCancel()"></app-new-daise-index-dialog>
</p-dialog>

<p-dialog [(visible)]="deleteItemDialog" [modal]="true" [style]="{width:'450px'}" header="Confirm">
  <div class="d-flex align-items-center">
    <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem"></i>
    <div>
      <span >Delete</span>
      <strong class="ms-1">{{item?.name}}</strong>?
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
            (click)="deleteItemDialog = false"></button>
    <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes"
            (click)="onDeleteConfirm()"></button>
  </ng-template>
</p-dialog>
