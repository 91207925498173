<form *ngIf="form" [formGroup]="form">
  <app-form-field *ngIf="aiModels.length" [id]="'gptVersion'" [label]="'Model Version'" [tooltip]="getTooltip(settingsItemsEnum.gptVersion)">
    <p-dropdown id="gptVersion" [(ngModel)]="selectedModel" [options]="aiModels" optionDisabled="isDisabled" optionLabel="displayName"
                (onChange)="gptVersionChanged($event)" [ngModelOptions]="{standalone: true}"></p-dropdown>
  </app-form-field>

    <app-form-field [id]="'prompt'" [label]="'Prompt'" [tooltip]="getTooltip(settingsItemsEnum.prompt)">
      <textarea pInputTextarea id="prompt" formControlName="prompt" type="text"></textarea>
    </app-form-field>

  <div [hidden]="hideSettings">
    <app-form-field [id]="'maxTokens'" [label]="'Max response'" [tooltip]="getTooltip(settingsItemsEnum.maxTokens)">
      <input pInputText id="maxTokens" formControlName="maxTokens" type="number" [min]="settingsRanges.maxTokensMin"
             [max]="settingsRanges.maxTokensMax" [step]="1">
      <p-slider id="maxTokensSlider" formControlName="maxTokens" [min]="settingsRanges.maxTokensMin"
                [max]="settingsRanges.maxTokensMax" [step]="1"></p-slider>
    </app-form-field>

    <app-form-field [id]="'temperature'" [label]="'Temperature'" [tooltip]="getTooltip(settingsItemsEnum.temperature)">
      <p-inputNumber id="temperature" formControlName="temperature" type="number" [min]="settingsRanges.temperatureMin"
                     [max]="settingsRanges.temperatureMax" [step]="0.01"
                     mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
      <p-slider id="temperatureSlider" formControlName="temperature" [min]="settingsRanges.temperatureMin"
                [max]="settingsRanges.temperatureMax" [step]="0.01"></p-slider>
    </app-form-field>

    <app-form-field [id]="'nucleusSamplingFactor'" [label]="'Top P (Nucleus Sampling Factor)'" [tooltip]="getTooltip(settingsItemsEnum.nucleusSamplingFactor)">
      <p-inputNumber id="nucleusSamplingFactor" formControlName="nucleusSamplingFactor" type="number"
                     [min]="settingsRanges.nucleusSamplingFactorMin" [max]="settingsRanges.nucleusSamplingFactorMax" [step]="0.01"
                     mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
      <p-slider id="nucleusSamplingFactorSlider" formControlName="nucleusSamplingFactor" [min]="settingsRanges.nucleusSamplingFactorMin"
                [max]="settingsRanges.nucleusSamplingFactorMax" [step]="0.01"></p-slider>
    </app-form-field>

    <app-form-field [id]="'lastMessages'" [label]="'Last messages'" [tooltip]="getTooltip(settingsItemsEnum.lastMessages)">
      <input pInputText id="lastMessages" formControlName="lastMessages" type="number" [min]="settingsRanges.lastMessagesMin"
             [max]="settingsRanges.lastMessagesMax" [step]="1">
      <p-slider id="lastMessagesSlider" formControlName="lastMessages" [min]="settingsRanges.lastMessagesMin"
                [max]="settingsRanges.lastMessagesMax" [step]="1"></p-slider>
    </app-form-field>

    <!--  <app-form-field [id]="'stopSequence'" [label]="'Stop sequence'" [tooltip]="getTooltip('stopSequence')">-->
    <!--    <input pInputText id="stopSequence" [(ngModel)]="stopSequence" [ngModelOptions]="{standalone: true}" type="text">-->
    <!--  </app-form-field>-->

    <app-form-field [id]="'frequencyPenalty'" [label]="'Frequency penalty'" [tooltip]="getTooltip(settingsItemsEnum.frequencyPenalty)">
      <p-inputNumber id="frequencyPenalty" formControlName="frequencyPenalty" type="number" [min]="settingsRanges.frequencyPenaltyMin"
                     [max]="settingsRanges.frequencyPenaltyMax" [step]="0.01"
                     mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
      <p-slider id="frequencyPenaltySlider" formControlName="frequencyPenalty" [min]="settingsRanges.frequencyPenaltyMin"
                [max]="settingsRanges.frequencyPenaltyMax" [step]="0.01"></p-slider>
    </app-form-field>

    <app-form-field [id]="'presencePenalty'" [label]="'Presence penalty'" [tooltip]="getTooltip(settingsItemsEnum.presencePenalty)">
      <p-inputNumber id="presencePenalty" formControlName="presencePenalty" type="number" [min]="settingsRanges.presencePenaltyMin"
                     [max]="settingsRanges.presencePenaltyMax" [step]="0.01"
                     mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
      <p-slider id="presencePenaltySlider" formControlName="presencePenalty" [min]="settingsRanges.presencePenaltyMin"
                [max]="settingsRanges.presencePenaltyMax" [step]="0.01"></p-slider>
    </app-form-field>
  </div>

  <div class="col-12 mt-4">
    <button class="float-end" pButton pRipple label="Reset to default" (click)="resetToDefaultSettings()" [loading]="false"></button>
  </div>

</form>
