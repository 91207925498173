<form *ngIf="newIndexForm" [formGroup]="newIndexForm">
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="card p-fluid">

        <div class="d-flex column-gap-3 mt-2 mt-2">
          <div class="field col">
            <label for="name">Name</label>
            <div>
              <input pInputText id="name" type="text" formControlName="name" required/>
              <app-form-error-message [form]="newIndexForm" controlName="name"></app-form-error-message>
            </div>
          </div>
          <div class="field col">
            <label for="indexName">Index Name</label>
            <div>
              <input pInputText id="indexName" type="text" formControlName="indexName" required/>
              <app-form-error-message [form]="newIndexForm" controlName="indexName"></app-form-error-message>
            </div>
          </div>
        </div>

        <div class="d-flex column-gap-3 mt-2 mt-2">
          <div class="field col">
            <label for="role">Role</label>
            <div>
              <p-dropdown id="role" [options]="roleOptions" formControlName="role" placeholder="Select role" appendTo="body"></p-dropdown>
              <app-form-error-message [form]="newIndexForm" controlName="role"></app-form-error-message>
            </div>
          </div>
          <div class="field col">
            <label for="model">Model</label>
            <div>
              <p-dropdown id="model" [options]="AiModelOptions" formControlName="model" optionLabel="title" optionValue="id" placeholder="Select model" appendTo="body"></p-dropdown>
              <app-form-error-message [form]="newIndexForm" controlName="model"></app-form-error-message>
            </div>
          </div>
        </div>

        <div class="field grid mt-2">
          <label for="prompt" class="col-12 mb-2 md:col-2 md:mb-0">Prompt</label>
          <div class="col-12 md:col-10">
            <textarea pInputTextarea id="prompt" rows="3" cols="30" formControlName="prompt" required></textarea>
            <app-form-error-message [form]="newIndexForm" controlName="prompt"></app-form-error-message>
          </div>
        </div>

        <div class="d-flex column-gap-3 mt-2">
          <div class="field col">
            <label for="lastMessages">Last Messages</label>
            <div>
              <input pInputText id="lastMessages" type="number" formControlName="lastMessages" required [min]="0" [max]="100"/>
              <app-form-error-message [form]="newIndexForm" controlName="lastMessages"></app-form-error-message>
            </div>
          </div>
          <div class="field col">
            <label for="temperature">Temperature</label>
            <div>
              <input pInputText id="temperature" type="number" formControlName="temperature" required [min]="0" [max]="1" [step]="0.01"/>
              <app-form-error-message [form]="newIndexForm" controlName="temperature"></app-form-error-message>
            </div>
          </div>
        </div>

        <div class="d-flex column-gap-3 mt-2">
          <div class="field col">
            <label for="strictness">Strictness</label>
            <div>
              <input pInputText id="strictness" type="number" formControlName="strictness" required/>
              <app-form-error-message [form]="newIndexForm" controlName="strictness"></app-form-error-message>
            </div>
          </div>
          <div class="field col">
            <label for="documentCount">Document Count</label>
            <div>
              <input pInputText id="documentCount" type="number" formControlName="documentCount" required [min]="0" [max]="100"/>
              <app-form-error-message [form]="newIndexForm" controlName="documentCount"></app-form-error-message>
            </div>
          </div>
        </div>

        <div class="d-flex column-gap-3 mt-2">
          <div class="field col">
            <label for="queryType">Query Type</label>
            <div>
              <p-dropdown id="queryType" [options]="QueryTypeOptions" formControlName="queryType" optionLabel="title" optionValue="id"  placeholder="Select query type" appendTo="body"></p-dropdown>
              <app-form-error-message [form]="newIndexForm" controlName="queryType"></app-form-error-message>
            </div>
          </div>
          <div class="field col" [ngStyle]="{'visibility': isQueryTypeSemantic() ? 'visible' : 'hidden'}">
            <label for="semantic">Semantic Configuration </label>
            <div>
              <input pInputText id="semantic" type="text" formControlName="semantic"/>
              <app-form-error-message [form]="newIndexForm" controlName="semantic"></app-form-error-message>
            </div>
          </div>
        </div>

        <div class="field grid mt-2">
          <label for="endpoint" class="col-12 mb-2 md:col-2 md:mb-0" >Endpoint</label>
          <div class="col-12 md:col-10">
            <input pInputText id="endpoint" type="text" formControlName="endpoint" required/>
            <app-form-error-message [form]="newIndexForm" controlName="endpoint"></app-form-error-message>
          </div>
        </div>
        <div class="field grid mt-2">
          <label for="vectorizationSourceDeploymentName" class="col-12 mb-2 md:col-2 md:mb-0" >Vectorization Source Deployment Name</label>
          <div class="col-12 md:col-10">
            <input pInputText id="vectorizationSourceDeploymentName" type="text" formControlName="vectorizationSourceDeploymentName" required/>
            <app-form-error-message [form]="newIndexForm" controlName="vectorizationSourceDeploymentName"></app-form-error-message>
          </div>
        </div>

        <div class="field grid mt-2">
          <label for="fieldMapping" class="col-12 mb-2 md:col-2 md:mb-0" >Field mapping</label>
          <div class="col-12 md:col-10">
            <p-checkbox id="fieldMapping" [(ngModel)]="fieldMapping" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
        </div>

        <div *ngIf="fieldMapping">
          <div class="field grid mt-2">
            <label for="fieldMappingTitle" class="col-12 mb-2 md:col-2 md:mb-0" >Field Mapping Title</label>
            <div class="col-12 md:col-10">
              <input pInputText id="fieldMappingTitle" type="text" formControlName="fieldMappingTitle"/>
              <app-form-error-message [form]="newIndexForm" controlName="fieldMappingTitle"></app-form-error-message>
            </div>
          </div>
          <div class="field grid mt-2">
            <label for="fieldMappingUrl" class="col-12 mb-2 md:col-2 md:mb-0" >Field Mapping URL</label>
            <div class="col-12 md:col-10">
              <input pInputText id="fieldMappingUrl" type="text" formControlName="fieldMappingUrl"/>
              <app-form-error-message [form]="newIndexForm" controlName="fieldMappingUrl"></app-form-error-message>
            </div>
          </div>
          <div class="field grid mt-2">
            <label for="fieldMappingFilePath" class="col-12 mb-2 md:col-2 md:mb-0" >Field Mapping File Path</label>
            <div class="col-12 md:col-10">
              <input pInputText id="fieldMappingFilePath" type="text" formControlName="fieldMappingFilePath"/>
              <app-form-error-message [form]="newIndexForm" controlName="fieldMappingFilePath"></app-form-error-message>
            </div>
          </div>
          <div class="field grid mt-2">
            <label for="fieldMappingContentFieldNames" class="col-12 mb-2 md:col-2 md:mb-0" >Field Mapping Content Field Names</label>
            <div class="col-12 md:col-10">
              <p-chips id="fieldMappingContentFieldNames" formControlName="fieldMappingContentFieldNames" />
              <app-form-error-message [form]="newIndexForm" controlName="fieldMappingContentFieldNames"></app-form-error-message>
            </div>
          </div>
          <div class="field grid mt-2">
            <label for="fieldMappingVectorFieldNames" class="col-12 mb-2 md:col-2 md:mb-0" >Field Mapping Vector Field Names</label>
            <div class="col-12 md:col-10">
              <p-chips id="fieldMappingVectorFieldNames" formControlName="fieldMappingVectorFieldNames" />
              <app-form-error-message [form]="newIndexForm" controlName="fieldMappingVectorFieldNames"></app-form-error-message>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>

<div class="mt-3 d-flex column-gap-3 justify-content-end">
  <button pButton pRipple type="button" class="p-button-secondary h-3rem w-full sm:w-auto" icon="pi pi-times"
          label="Cancel" (click)="onCancel()"></button>
  <button pButton pRipple type="button" class="h-3rem w-full sm:w-auto" icon="pi pi-check"
          label="Confirm" (click)="onSubmit()" [loading]="processing"></button>
</div>
