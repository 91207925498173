<p-tabView>
  <p-tabPanel header="Search">
    <div *ngIf="files.length"><strong>
        ({{files?.length}} / {{maxNumberOfFiles}} files | {{formatSize(filesSizeSum)}})
    </strong></div>
    <div class="d-flex flex-column">
      <div class="flex-fill">
        <p-table *ngIf="files" [value]="files" dataKey="name">
          <ng-template pTemplate="body" let-file let-index="rowIndex">
            <tr>
              <td><i [class]="getFileIcon(file.name)"></i></td>
              <td class="text-truncate">{{ file.name }}</td>
              <td class="text-end">
                <p-button icon="pi pi-trash"
                          styleClass="width-fix"
                          (onClick)="deleteFile(false, file, index)"
                          [rounded]="true"
                          [text]="true"
                          severity="secondary"
                          size="small"
                          [disabled]="file.uploaded === fileUploadEnum.UPLOADING"
                />
              </td>
              <td class="text-end">
                <i [class]="getUploadStatusIcon(file)"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <app-file-upload
          [files]="files"
          [filesObservable]="files$"
          [multiple]="true"
          [autoUpload]="false"
          [showFiles]="false"
          [acceptedFileFormat]="appConstants.ACCEPTED_FILE_FORMATS_FILES"
          (onFileSelected)="addFiles($event, false)"
          [disableFileSelection]="uploading"
        >
        </app-file-upload>
        <div class="d-flex justify-content-end mt-2">
          <p-button [label]="!uploading ? 'Upload' : 'Uploading' " severity="secondary" icon="pi pi-cloud-upload"
                    [loading]="uploading" [disabled]="newFiles.length < 1 || areAllFilesUploaded(false)" (click)="uploadFiles(false)"></p-button>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Code">
    <div *ngIf="codeInterpreterFiles.length"><strong>
      ({{codeInterpreterFiles?.length}} / {{maxNumberOfFiles}} files | {{formatSize(codeInterpreterFilesSizeSum)}})
    </strong></div>
    <p-table *ngIf="codeInterpreterFiles" [value]="codeInterpreterFiles" [dataKey]="'name'">
      <ng-template pTemplate="body" let-file let-index="rowIndex">
        <tr>
          <td><i [class]="getFileIcon(file.name)"></i></td>
          <td class="text-truncate">{{ file.name }}</td>
          <td class="text-end">
            <p-button icon="pi pi-trash"
                      styleClass="width-fix"
                      (onClick)="deleteFile(true, file, index)"
                      [rounded]="true"
                      [text]="true"
                      severity="secondary"
                      size="small"
                      [disabled]="file.uploaded === fileUploadEnum.UPLOADING"
            />
          </td>
          <td class="text-end">
            <i [class]="getUploadStatusIcon(file)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <app-file-upload
      [files]="codeInterpreterFiles"
      [filesObservable]="codeInterpreterFiles$"
      [multiple]="true"
      [autoUpload]="false"
      [showFiles]="false"
      [acceptedFileFormat]="appConstants.ACCEPTED_FILE_FORMATS_CODE_INTERPRETERS"
      (onFileSelected)="addFiles($event, true)"
      [disableFileSelection]="uploading"
    >
    </app-file-upload>
    <div class="d-flex justify-content-end mt-2">
      <p-button [label]="!uploading ? 'Upload' : 'Uploading' " severity="secondary" icon="pi pi-cloud-upload"
                [loading]="uploading" [disabled]="newFiles.length < 1 || areAllFilesUploaded(true)" (click)="uploadFiles(true)"></p-button>
    </div>

  </p-tabPanel>

</p-tabView>
