import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUtils } from '../../utils/file-utils/file-utils.component';

@Component({
  selector: 'app-chat-assistant-files',
  templateUrl: './chat-assistant-files.component.html',
  styleUrl: './chat-assistant-files.component.scss'
})
export class ChatAssistantFilesComponent {
  @Input() files: File[];
  @Input() title: string;
  @Input() acceptedFiles: string;

  @Output() onFilesChange = new EventEmitter<File[]>();

  filesSizeSum = 0;

  readonly maxNumberOfFiles = 20;
  private readonly maxTotalFilesSize = 512000000;

  get acceptedTypesFormatted(): string {
    return FileUtils.getAcceptedTypesFormatted(this.acceptedFiles)
  }

  constructor(private readonly config: PrimeNGConfig, private readonly messageService: MessageService) { }

  handleFileSelect(files: File[]) {
    const newFiles = files.filter(x => !this.files.includes(x));
    let filesSizeSum = files.reduce((acc, file) => acc + file.size, 0);
    if (filesSizeSum > this.maxTotalFilesSize) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'The total size of the files exceeds the limit of 512 MB.'
      })
      while(newFiles.length && filesSizeSum > this.maxTotalFilesSize) {
        files.pop();
        newFiles.pop();
        filesSizeSum = files.reduce((acc, file) => acc + file.size, 0)
      }
    }

    this.files.push(...newFiles);
    this.files = [...this.files];
    this.filesSizeSum = filesSizeSum;
    this.onFilesChange.emit(this.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.files = [...this.files];
    this.onFilesChange.emit(this.files);
  }

  getFileIcon(filename: string | undefined): string {
    return FileUtils.getFileIcon(filename);
  }

  formatSize(bytes: number): string {
    return FileUtils.formatSize(bytes, this.config.translation.fileSizeTypes);
  }
}
