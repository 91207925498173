import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { AzureLoggerService } from './azure-logger.service';
import { ApiErrorResponseModel } from 'digiteq-ai-portal-client-lib';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private messageService: MessageService,
    private azureService: AzureLoggerService,
  ) { }

  handleError(error: ApiErrorResponseModel | HttpErrorResponse | string, detail: string) {
    if (typeof error === 'string') {
      this.messageService.add({
        severity: 'error',
        summary: error,
        detail,
        life: 3000,
      });
      return;
    }

    const err = (error instanceof HttpErrorResponse) ? error.error : error;
    console.error(err);
    this.messageService.add({
      severity: 'error',
      detail,
      summary: err.description || err.type || 'Error',
      life: 3000,
    });
    this.azureService.logError(err);

  }

}
