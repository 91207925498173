import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IndexAdminModel, IndexService, QueryType, UserRole } from 'digiteq-ai-portal-client-lib';
import { MessageService } from 'primeng/api';
import { FormUtils } from '../../utils/form-utils/form-utils';
import { finalize } from 'rxjs';
import { AiModelOptions } from '../../constants/AiModelOptions';
import { QueryTypeOptions } from '../../constants/QueryTypeOptions';

@Component({
  selector: 'app-new-daise-index-dialog',
  templateUrl: './new-daise-index-dialog.component.html',
  styleUrl: './new-daise-index-dialog.component.scss'
})
export class NewDaiseIndexDialogComponent implements OnInit {
  @Input() editedItem: IndexAdminModel | null = null;

  @Output() onIndexCancel = new EventEmitter<void>();
  @Output() onNewIndexCreated = new EventEmitter<IndexAdminModel>();
  @Output() onIndexEdited = new EventEmitter<IndexAdminModel>();

  newIndexForm?: FormGroup;

  fieldMapping = false;
  processing = false;

  protected readonly roleOptions: string[] = Object.values(UserRole);
  protected readonly AiModelOptions = AiModelOptions;
  protected readonly QueryTypeOptions = QueryTypeOptions;

  constructor(
    private fb: FormBuilder,
    private indexService: IndexService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.newIndexForm = this.fb.group({
      role: [this.editedItem?.role || null, Validators.required],
      enabled: [this.editedItem?.enabled || true],
      'default': [this.editedItem?.default || false],
      model: [this.editedItem?.model || null, Validators.required],
      prompt: [this.editedItem?.prompt || '', Validators.required],
      lastMessages: [this.editedItem?.lastMessages || null, Validators.required],
      temperature: [this.editedItem?.temperature || null, Validators.required],
      semantic: [this.editedItem?.semantic || ''],
      strictness: [this.editedItem?.strictness || null],
      documentCount: [this.editedItem?.documentCount || null, Validators.required],
      queryType: [this.editedItem?.queryType || null, Validators.required],
      indexName: [this.editedItem?.indexName || '', Validators.required],
      endpoint: [this.editedItem?.endpoint || '', Validators.required],
      vectorizationSourceDeploymentName: [this.editedItem?.vectorizationSourceDeploymentName || '', Validators.required],
      fieldMappingTitle: [this.editedItem?.fieldMappingTitle || ''],
      fieldMappingUrl: [this.editedItem?.fieldMappingUrl || ''],
      fieldMappingFilePath: [this.editedItem?.fieldMappingFilePath || ''],
      fieldMappingContentFieldNames: [this.editedItem?.fieldMappingContentFieldNames || []],
      fieldMappingVectorFieldNames: [this.editedItem?.fieldMappingVectorFieldNames || []],
      name: [this.editedItem?.name || '', Validators.required]
    })
    if (this.editedItem?.id) {
      this.newIndexForm.addControl('id', this.fb.control(this.editedItem.id));
    }
    if (this.newIndexForm.get('fieldMappingTitle').value) {
      this.fieldMapping = true;
    }
  }

  isQueryTypeSemantic(): boolean {
    const queryType = this.newIndexForm?.get('queryType')?.value;
    const semanticQueryTypes = [QueryType.Semantic, QueryType.VectorSemanticHybrid];
    return semanticQueryTypes.includes(queryType);
  }

  onCancel() {
    this.newIndexForm.reset();
    this.onIndexCancel.emit();
  }

  onSubmit() {
    this.validateFormControls();
    if (!FormUtils.checkIfFormGroupValid(this.newIndexForm, this.messageService)) {
      return;
    }

    if (!this.editedItem) {
      this.createIndex(this.newIndexForm.value);
    } else  {
      this.updateIndex(this.newIndexForm.value);
    }

  }

  validateFormControls() {
    let semanticValidators = [Validators.required];
    let semanticValue = this.newIndexForm.get('semantic')?.value;
    if (!this.isQueryTypeSemantic()) {
      semanticValidators = [];
      semanticValue = null;
    }
    this.newIndexForm.get('semantic')?.setValidators(semanticValidators);
    this.newIndexForm.get('semantic')?.reset(semanticValue);

    const fieldsToValidate = ['fieldMappingTitle', 'fieldMappingContentFieldNames'];
    fieldsToValidate.forEach(field => {
      const formControl = this.newIndexForm.get(field);
      formControl?.setValidators(this.fieldMapping ? [Validators.required] : []);
      formControl?.reset(formControl?.value);
    })

    const queryTypeFormControl = this.newIndexForm.get('fieldMappingVectorFieldNames');
    const queryTypesWithMandatoryVectorFieldNames = [QueryType.Vector, QueryType.VectorSemanticHybrid, QueryType.VectorSimpleHybrid];
    if (queryTypesWithMandatoryVectorFieldNames.includes(this.newIndexForm.get('queryType')?.value)) {
      queryTypeFormControl?.setValidators(this.fieldMapping ? [Validators.required] : []);
      queryTypeFormControl?.reset(queryTypeFormControl?.value);
    }

  }

  createIndex(indexValue: IndexAdminModel) {
    this.indexService.createIndex(indexValue)
      .pipe(
        finalize(() => {
          this.processing = false;
        })
      )
      .subscribe({
        next: (newIndex) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Index created successfully' });
          this.onNewIndexCreated.emit(newIndex);
          this.onCancel();
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Index creation failed' });
        },
      })
  }

  updateIndex(indexValue: IndexAdminModel) {
    this.indexService.updateIndex(indexValue.id, indexValue).subscribe({
      next: (editedIndex) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Index updated successfully' });
        this.onIndexEdited.emit(editedIndex);
        this.onCancel();
      },
      error: () => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Index update failed'});
      },
    })
  }
}
