import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  ApiErrorResponseModel,
  AssistantRequestModel,
  AssistantResponseModel,
  AssistantService,
  AssistantThreadListModel,
  AssistantThreadModel,
  ChatConstants,
  ChatHistoryListModel,
  ChatHistoryModel,
  ChatResponseModel,
  ChatSettings,
  CompanyChatRequestModel,
  CompanyChatResponseModel,
  ErrorType,
  GenericChatRequestModel,
  IndexAdminModel,
  IndexService,
  MessageModel,
  MessageRole,
  OpenAIModelType,
} from 'digiteq-ai-portal-client-lib';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SettingsService } from '../../services/settings.service';
import { Sidebar } from 'primeng/sidebar';
import { CitationFeModel, FeMessageModel } from '../../models/fe-message.model';
import { ChatType } from '../../models/chat-type';
import { StreamChatService } from "../../services/stream-chat.service";
import { Observable } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { historyCategoryEnum } from '../../models/history-category.enum';
import { get_encoding, Tiktoken } from 'tiktoken';
import _ from 'lodash';
import { KatexOptions } from 'ngx-markdown';
import { appConstants } from '../../constants/app-constants';
import { environment } from '../../../environments/environment';
import { ThreadMessageModel } from 'digiteq-ai-portal-client-lib/model/threadMessageModel';
import { FileUtils } from '../../utils/file-utils/file-utils.component';
import { AiAssistantService } from '../../services/ai-assistant.service';
import { CompanyChatService } from '../../company-chat.service';
import { FileModel, FileUploadEnum } from '../../models/file-model';

interface ChatHistoryListUiModel {
  items: ChatHistoryListModel[];
  label: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [MessageService]
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isAssistant()) {
      return;
    }
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      if ((target as HTMLAnchorElement).href.includes('download%%')) {
        this.downloadFile(event, (target as HTMLAnchorElement));
      }
    }
  }


  @ViewChild("chatContainer") chatContainer: ElementRef<HTMLElement>;
  @ViewChild("historySidebar") historySidebar: Sidebar;
  @ViewChild("settingsSidebar") settingsSidebar: Sidebar;
  @ViewChild("assistantSettingsSidebar") assistantSettingsSidebar: Sidebar;
  @ViewChild("textArea") textArea: ElementRef<HTMLElement>;

  @Input() chatType: ChatType;
  @Input() description: string;

  private _settingsVisible = false;
  get settingsVisible(): boolean {
    return this._settingsVisible;
  }

  set settingsVisible(value: boolean) {
    if (this._settingsVisible !== value) {
      this._settingsVisible = value;
    }
  }

  private _historyVisible = false;
  get historyVisible(): boolean {
    return this._historyVisible;
  }

  set historyVisible(value: boolean) {
    if (this._historyVisible !== value) {
      this._historyVisible = value;
    }
  }

  private _currentChatId: string | number | undefined;
  set currentChatId(value: string | number | undefined) {
    this._currentChatId = value;
    if (this.isAssistant()) {
      this.aiAssistantService.threadId = value as number | undefined;
    }
  }

  domObserver: MutationObserver;
  role = MessageRole;

  private _messages: FeMessageModel[] = [];
  get messages(): FeMessageModel[] {
    return this._messages;
  }

  set messages(value: FeMessageModel[]) {
    this._messages = value;
    this.showWelcomeMsg = !value.length;
  }

  form?: FormGroup;
  messageForm: FormControl;
  aiTypingResponse = false;
  aiResponseError = false;
  enterKeyDown = false;
  currentChatName: string | undefined;
  readonly currentChatNamePlaceholder = 'Your new chat';
  items: MenuItem[] | undefined;
  showWelcomeMsg = true;
  history: ChatHistoryListModel[];
  groupedHistory: ChatHistoryListUiModel[];
  loadingChatHistory: boolean;
  stopped = false;
  promptViolation = false;
  displayFilesDialog: boolean = false;
  messageFileItems: File[] = [];
  messageCodeInterpreterFiles: File[] = [];
  assistantAttachments: string[];
  images: string[] = [];
  availableIndexes: IndexAdminModel[] = [];

  chatTypeEnum = ChatType;
  tokenEncoding: Tiktoken;
  constants: ChatConstants;
  assistantSettingsVisible: boolean;
  companyChatSettingsVisible: boolean;
  readonly appConstants = appConstants;

  katexOptions: KatexOptions = {
    throwOnError: false,
    displayMode: true,
    delimiters: [{left: "$$", right: "$$", display: true}, {left: "$", right: "$", display: false}]
  }

  constructor(
    private chatService: StreamChatService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private errorService: ErrorService,
    private confirmationService: ConfirmationService,
    private assistantService: AssistantService,
    private fb: FormBuilder,
    private aiAssistantService: AiAssistantService,
    private daiseService: CompanyChatService,
    private indexService: IndexService,
  ) {
    this.tokenEncoding = get_encoding('cl100k_base');
  }

  ngOnInit() {
    this.loadChatHistory();
    this.loadConstants();
    if (this.chatType === ChatType.COMPANY) {
      this.loadDaiseIndexes();
    }


    this.messageForm = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      this.notOnlyWhitespace(),
      this.messagesExceedMaxNumberOfToken()
    ]);

    if (this.isAssistant()) {
      this.form = this.fb.group({
        threadId: [null, Validators.required],
        message: ['', Validators.required],
        indexId: [null, Validators.required],
      });
    }
  }

  ngAfterViewInit() {
    //pInputTextarea autoresize does not show scrollbar when input is too height
    this.textArea.nativeElement.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight + 5) + 'px';
    });
    this.observeChangesInDom();
  }

  ngOnDestroy() {
    this.domObserver.disconnect();
  }

  private notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value.trim() === '') {
        return {'whitespace': true};
      }
      return null;
    };
  }

  private messagesExceedMaxNumberOfToken(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let maxTokens: number;
      if (this.chatType === ChatType.COMPANY) {
        maxTokens = this.constants?.maxRequestTokensGpt4oMini
      } else {
        switch (this.settingsService.gptVersion) {
          case OpenAIModelType.Gpt35:
            maxTokens = this.constants?.maxRequestTokensGpt35;
            break;
          case OpenAIModelType.Gpt4:
            maxTokens = this.constants?.maxRequestTokensGpt4;
            break;
          case OpenAIModelType.Gpt4oMini:
            maxTokens = this.constants?.maxRequestTokensGpt4oMini;
            break;
          case OpenAIModelType.O1:
            maxTokens = this.constants?.maxRequestTokensO1;
            break;
          case OpenAIModelType.O3Mini:
            maxTokens = this.constants?.maxRequestTokensO3Mini;
            break;
        }
      }
      if (this.getMessagesTokenLength(control.value) > maxTokens) {
        return {'maxNumOfTokens': true};
      }
      return null;
    };
  }

  addMessage(newMessage: MessageModel | ThreadMessageModel) {
    const currentMessages = this.messages;
    let assistantMessage: FeMessageModel;
    if (this.isAssistant()) {
      assistantMessage = {
        ...newMessage,
        files: this.messageFileItems.length
          ? this.messageFileItems?.map(f => f.name)
          : (newMessage as ThreadMessageModel).searchFiles?.map(f => f.name),
        codeFiles: this.messageCodeInterpreterFiles.length
          ? this.messageCodeInterpreterFiles?.map(f => f.name)
          : (newMessage as ThreadMessageModel).codeInterpreters?.map(f => f.name),
      }
      currentMessages.push(assistantMessage);
    } else {
      currentMessages.push(newMessage)
    }
    this.messages = currentMessages;
  }

  stop() {
    if (!this._currentChatId) {
      return;
    }
    if (this.chatType != ChatType.ASSISTANT) {
      this.chatService.stopChat(this._currentChatId as string).subscribe(() => {
        this.aiTypingResponse = false;
      });
    } else {
      this.assistantService.stopAssistantChat(+this._currentChatId).subscribe(() => {
        this.aiTypingResponse = false;
      });
    }
  }

  handleActionButton() {
    if (this.aiTypingResponse) {
      this.stop();
    } else {
      this.send();
    }
  }

  send(resend?: boolean) {
    this.enterKeyDown = true;

    if (!this.messageForm.valid && !resend) return;

    let message = this.messageForm.value;
    if (resend) {
      const index = this.stopped ? 2 : 1;
      if (this._messages.length - index > 0) {
        message = this._messages[this._messages.length - index].message;
        this._messages.splice(this._messages.length - index);
      }
    }

    this.enterKeyDown = false;
    this.aiResponseError = false;
    this.stopped = false;

    if (message) {
      this.addMessage({
        message,
        role: MessageRole.User,
      });
    }

    const lastMessages = this.settingsService.savedSettings?.lastMessages || 5;
    let assistantChat: AssistantRequestModel;
    if (this.isAssistant()) {
      this.form?.controls['threadId'].setValue(this.aiAssistantService.threadId);
      this.form?.controls['message'].setValue(this.messages[this.messages.length - 1].message);
      assistantChat = this.form.value;
    }
    const chat: GenericChatRequestModel | CompanyChatRequestModel = {
      messages: this.messages.slice(-lastMessages),
      chatId: this._currentChatId as string
    };
    this.scrollToBottom();
    this.aiTypingResponse = true;
    this.textArea.nativeElement.style.height = '';

    this.currentChatName = this.currentChatNamePlaceholder;

    this.sendChatMsg(chat, assistantChat, this.messageFileItems, this.messageCodeInterpreterFiles);
    this.messageForm.reset();
    this.form.reset();
    this.images = [];
  }

  sendChatMsg(chat: GenericChatRequestModel | CompanyChatRequestModel, assistantChat: AssistantRequestModel, searchFiles?: Array<File>, interpreters?: Array<File>) {
    let chatStreamObservable$: Observable<any>;
    switch (this.chatType) {
      case ChatType.GENERIC: {
        (chat as GenericChatRequestModel).settings = this.settingsService.savedSettings;
        chatStreamObservable$ = this.chatService.realGenericChatStream(chat);
      }
        break;
      case ChatType.COMPANY: {
        (chat as CompanyChatRequestModel).indexId = this.daiseService.daiseIndex.id;
        chatStreamObservable$ = this.chatService.realCompanyChatStream(chat);
      }
        break;
      case ChatType.ASSISTANT: {
        chatStreamObservable$ = this.chatService.realAssistantChatStream(assistantChat, searchFiles, interpreters);
        break;
      }
    }

    this.aiTypingResponse = true;
    let firstMessage = true;

    chatStreamObservable$.subscribe({
      next: response => {
        if (response?.imageId) {
          this.images.push(response.imageId);
          response.text = `![ai-image](${ response.imageId })\n\n`;
        }
        const regex = /【(.*?)】/g;
        const test = regex.test(response.text);
        if (!test) {
          this.handleChatResponse(response, firstMessage);
          if (this.messages.length > 1) {
            this.messages.forEach((m, index) => {
              if (m.role === MessageRole.Assistant && m.message === '\n```' && index < this.messages.length - 1) {
                this.messages = this.messages.filter((_, i) => i !== index);
              }
            })
          }
        }
        firstMessage = false;
        this.messageForm.reset();
        this.messageFileItems = [];
        this.messageCodeInterpreterFiles = [];
      },
      error: err => {
        this.handleChatError(err);
      },
      complete: () => {
        this.aiTypingResponse = false;
        const lastMessage = this.messages[this.messages.length - 1];
        lastMessage.completed = true;
        lastMessage.message = this.searchForPatternsToReplace(lastMessage.message, this.assistantAttachments);
        // TODO: there might be a better way than call API
        this.loadChatHistory();
      },
    });
  }

  downloadFile(event: MouseEvent, link: HTMLAnchorElement) {
    if (link.href.includes('download%%')) {
      event.preventDefault();
      const regex = /download%%(.*?)%%/;
      const match = regex.exec(link.href)
      const fileId = match[1];
      this.assistantService.getGeneratedFileToken(fileId).subscribe({
        next: response => {
          const fileUrl = `${ environment.apiUrl }/assistant/generated-file/${ fileId }/download?token=${ response.token }`;
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = fileId;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(fileUrl);
        },
        error: err => {
          this.errorService.handleError(err, 'Could not get file.');
        }
      });
    }
  }

  private updateCitations(model: FeMessageModel) {
    if (!model.citations) {
      return;
    }

    let citationIndexes: number[] = [];

    const spanRegex = /<sup\s+class=["']citation-in-text["']>([1-5])<\/sup>/g;
    let match: RegExpExecArray;

    // when chatting with OpenAI, after few messages, the engine starts to generate responses now with doc1 etc.,
    // but with formatted span with class "citation-in-text"
    while ((match = spanRegex.exec(model.message)) !== null) {
      citationIndexes.push(parseInt(match[1], 10));
    }

    const docRegex = /\[doc(\d+)]/g;

    if (docRegex.test(model.message)) {
      model.message = model.message.replace(docRegex, (substring, idx) => {
        citationIndexes.push(Number(idx));
        return `<sup class="citation-in-text">${ idx }</sup>`;
      });
    }

    citationIndexes = [...new Set(citationIndexes)];

    model.citations.filter(c => citationIndexes.includes(c.index))
      .forEach(c => {
        c.used = true;
        c.title = this.decodeHtmlEntities(c.title);
      });
  }

  decodeHtmlEntities(input: string): string {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  handleChatResponse(response: ChatResponseModel | CompanyChatResponseModel | AssistantResponseModel, firstMessage: boolean) {
    const citations: CitationFeModel[] = (response as CompanyChatResponseModel).citations;
    let messagePart = response.text;
    // @ts-ignore
    if (response.updateType === 'StepEnd') {
      firstMessage = true;
    }
    // @ts-ignore
    if (response.updateType === 'Completed') {
      this.messages.splice(-1);
      return;
    }
    if ((response as AssistantResponseModel).attachments) {
      messagePart = '';
      this.assistantAttachments = (response as AssistantResponseModel).attachments;
    }
    if (citations) {
      this.updateCitationIndexes(citations);
    }

    if (firstMessage) {
      const propertyId = this.getChatIdProperty()
      if (response[propertyId]) {
        this.currentChatId = response[propertyId];
      }
      messagePart = messagePart || '';
      this.messages.push({
        message: messagePart,
        citations,
        role: MessageRole.Assistant,
      });
    } else {
      const lastMessage = this.messages[this.messages.length - 1];
      lastMessage.message += messagePart;
      this.updateCitations(lastMessage);
    }

    this.aiResponseError = false;
    this.stopped = false;
    this.scrollToBottom();
  }

  searchForPatternsToReplace(text: string, attachments: string[], images?: string[]): string {
    const linkPattern = /(?<!!)\[.*?]\(.*?\)/g;
    if (text.includes("[") && text.includes("](")) {
      const matches = text.match(linkPattern);
      matches?.forEach((match, index) => {
        const contentMatch = (/\[(.*?)]/).exec(match);
        if (attachments[index]) {
          const downloadPlaceholder = `${ contentMatch[0] }(download%%${ attachments[index] }%%)`;
          text = text.replace(match, downloadPlaceholder);
        }
      })
    }

    const imagePattern = /!\[.*?]\(.*?\)/g;
    if (text.includes("![") && text.includes("](")) {
      const matches = imagePattern.exec(text);
      matches.forEach((match, index) => {
        const contentMatch = (/\[(.*?)]/).exec(match);
        if (images[index] || this.images[index]) {
          this.downloadAndReplaceImage(images[index] || this.images[index], contentMatch, match, text);
        }
      });
    }

    const latexInlinePatternOne = /(?<!\\)\\\((.*?)(?<!\\)\\\)/g;
    const latexInlinePatterTwo = /(?<!\\)\\\[(.*?)(?<!\\)\\]/g;
    const latexBlockPatternOne = /\\\\\((.*?)\\\\\)/g;
    const latexBlockPatterTwo = /\\\\\[(.*?)\\\\]/g;

    text = text.replace(latexInlinePatternOne, (match, p1) => `$${ p1 }$`);
    text = text.replace(latexInlinePatterTwo, (match, p1) => `$${ p1 }$`);
    text = text.replace(latexBlockPatternOne, (match, p1) => `$$${ p1 }$$`);
    text = text.replace(latexBlockPatterTwo, (match, p1) => `$$${ p1 }$$`);


    return text;
  }

  private downloadAndReplaceImage(attachment: string, contentMatch: RegExpExecArray, match: string, text: string) {
    this.assistantService.downloadGeneratedFile(attachment).subscribe({
      next: response => {
        const image = new Blob([response], {type: 'image/png'});
        const url = URL.createObjectURL(image);
        replaceImageSrc(attachment, url);
        const imagePlaceholder = `!${ contentMatch[0] }(${ url })`;
        text = text.replace(match, imagePlaceholder);
        this.scrollToBottom(10)
      },
      error: err => {
        this.errorService.handleError(err, 'Could not get image.');
      }
    });

    const replaceImageSrc = (attachment: string, url: string) => {
      const imgElements = document.getElementsByTagName('img');
      Array.from(imgElements).forEach(imgElement => {
        if (imgElement.src.includes(attachment)) {
          imgElement.src = url;
          imgElement.style.width = '100%';
        }
      });
    }
  }

  handleChatError(err: ApiErrorResponseModel | string) {
    this.aiTypingResponse = false;
    if (err === 'Stream stopped') {
      this.stopped = true;
      return;
    }

    // remove last assistant's message client already started writing response
    if (this.messages[this.messages.length - 1].role === MessageRole.Assistant) {
      this.messages.splice(-1);
    }

    this.aiResponseError = true;

    let errDetail = 'Could not send your message.';
    if (typeof err !== 'string' && (err.type === ErrorType.ChatPromptError || err.type === ErrorType.ChatContentFilterError)) {
      errDetail = 'Your prompt is wrong or violates content rules. Please use a different prompt.'
      this.promptViolation = true;
    } else {
      this.promptViolation = false;
    }

    this.errorService.handleError(err, errDetail)
  }

  scrollToBottom(delay?: number): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({top: this.chatContainer.nativeElement.scrollHeight});
    }, delay);
  }

  reset() {
    this.messages = [];
    this.currentChatName = undefined;
    this.currentChatId = undefined;
    this.aiAssistantService.threadSearchFiles = [...[]];
    this.aiAssistantService.threadCodeInterpreterFiles = [...[]];
    this.messageFileItems = [...[]];
    this.messageCodeInterpreterFiles = [...[]];
    this.messageForm.enable();
  }

  async copyToClipboard(message: string) {
    message = message.replace(/^```|```$/g, '');
    await navigator.clipboard.writeText(message);
    this.messageService.add({
      severity: 'success',
      detail: 'Text copied to clipboard',
      summary: 'Copied!',
      life: 3000,
    });
  }

  getFormattedTextareaValue(text: string): string {
    text.replace(/\n/g, '<br>');
    return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  observeChangesInDom() {
    const targetNode = document.getElementById('chat-container');
    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(node => {
            if (node instanceof HTMLElement) {
              this.insertHeaderToMarkdownCodeSnippet(node)
            }
          });
        }
      }
    };
    this.domObserver = new MutationObserver(callback);
    this.domObserver.observe(targetNode, {childList: true, subtree: true});
  }

  insertHeaderToMarkdownCodeSnippet(node: HTMLElement) {
    if (node.nodeName !== 'PRE') {
      return;
    }
    const languageClass = Array.from(node.classList).find(className =>
      className.startsWith('language-')
    );

    if (languageClass && !node.querySelector('.markdown-code-header')) {
      node.style.paddingTop = '0';
      const languageName = languageClass?.split('-')[1];
      const markdownCodeHeader = document.createElement('div');
      markdownCodeHeader.classList.add('markdown-code-header');
      markdownCodeHeader.innerText = languageName === 'none' ? 'code snippet' : this.mapLanguageName(languageName);
      node.insertBefore(markdownCodeHeader, node.firstChild);
    }
  }

  private mapLanguageName(languageName: string) {
    switch (languageName) {
      case 'cpp':
        return 'c++';
      case 'csharp':
        return 'c#';
      default:
        return languageName;
    }
  }

  get hasInputValueErrors() {
    return (this.messageForm.hasError('minlength') || this.messageForm.hasError('whitespace'))
      && this.enterKeyDown && this.messageForm.value;
  }

  hasUsedCitation(model: FeMessageModel): boolean {
    return model.citations?.some(c => c.used);
  }

  loadChatHistory() {
    let chatHistoryListObservable$: Observable<ChatHistoryListModel[] | AssistantThreadListModel[]>;
    switch (this.chatType) {
      case ChatType.GENERIC: {
        chatHistoryListObservable$ = this.chatService.genericHistoryList();
      }
        break;
      case ChatType.COMPANY: {
        chatHistoryListObservable$ = this.chatService.companyHistoryList();
      }
        break;
      case ChatType.ASSISTANT: {
        chatHistoryListObservable$ = this.assistantService.assistantThreadList();
      }
        break;
    }

    this.loadingChatHistory = true;

    chatHistoryListObservable$.subscribe({
      next: chatHistory => {
        this.onSuccessChatHistoryLoad(chatHistory);
      },
      error: err => {
        this.loadingChatHistory = false;
        this.errorService.handleError(err, 'Could not get chat history.');
      }
    })
  }

  onSuccessChatHistoryLoad(chatHistory: any) {
    this.history = chatHistory;
    this.historyVisible = this.history.length > 0;
    this.categorizeChatHistory();

    const currentChat = this.history.find(chat => chat[this.getChatIdProperty()].toString() === this._currentChatId);
    this.currentChatName = currentChat?.name;
    this.loadingChatHistory = false;

    //Re-render settings component so selected GPT version can be reloaded.
    this.reloadSettingsSidebar();
  }

  onChatHistoryItemLoad(chat: ChatHistoryModel | AssistantThreadModel) {
    this.messageForm.reset();
    this.reset();

    chat.messages.forEach(m => {
      if (m.role == MessageRole.User) {
        this.addMessage(m);
      } else {
        let feMsg: FeMessageModel
        if (this.isAssistant()) {
          const tm = m as ThreadMessageModel;

          if (tm.codeInterpreterOutput?.length) {
            feMsg = {
              role: tm.role,
              message: '```\n' + tm.codeInterpreterOutput + "```\n"
            }
          } else {
            if (tm.images?.length) {
              const imagesString = tm.images.map((img: string) => `![ai-image](${ img })\n\n`).join('');
              tm.message = imagesString + tm.message;
            }
            feMsg = {
              message: this.searchForPatternsToReplace(tm.message, tm.responseFiles.map(f => f.fileId), tm.images),
              role: tm.role,
            };
          }
        } else {
          feMsg = {
            message: m.message,
            citations: m.citations,
            role: m.role,
          };
          this.updateCitationIndexes(feMsg.citations);
          this.updateCitations(feMsg);
        }

        this.messages.push(feMsg);
      }
    });

    if (this.isAssistant()) {
      this.aiAssistantService.threadSearchFiles = (chat as AssistantThreadModel).searchFiles?.map(f => new FileModel([], f.name, {},  FileUploadEnum.UPLOADED, f.id));
      this.aiAssistantService.threadCodeInterpreterFiles = (chat as AssistantThreadModel).interpreters?.map(f => new FileModel([], f.name, {},  FileUploadEnum.UPLOADED, f.id));
    } else {
      this.handleLoadedChatSettings((chat as ChatHistoryModel).settings);
    }
    this.currentChatId = chat[this.getChatIdProperty()]
    this.currentChatName = chat.name;

    this.aiResponseError = false;
    this.messageForm.enable();
    this.stopped = false;
    this.scrollToBottom();
  }

  onChatHistoryItemDelete(chatId: string) {
    const idProperty = this.getChatIdProperty();
    const index = this.history.findIndex(h => h[idProperty] === chatId);
    this.history.splice(index, 1);
    this.categorizeChatHistory();
    if (chatId.toString() === this._currentChatId) {
      this.reset();
    }
  }

  onChatRename(renameChatData: { id: string, newName: string }) {
    const idProperty = this.getChatIdProperty();
    const historyItem = this.history.find(h => h[idProperty] === renameChatData.id);
    historyItem.name = renameChatData.newName;
  }

  handleLoadedChatSettings(newSettings: ChatSettings) {
    if (!newSettings) {
      return;
    }
    if (_.isEqual(newSettings, this.settingsService.savedSettings)) {
      return;
    }
    this.confirmationService.confirm({
      target: null,
      message: 'Do you want to accept settings of the loaded chat?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.settingsService.savedSettings = newSettings;
      },
    });
  }

  categorizeChatHistory() {
    const currentDate = new Date();

    this.groupedHistory = [
      {label: historyCategoryEnum.last7days, items: []},
      {label: historyCategoryEnum.last30days, items: []},
      {label: historyCategoryEnum.older, items: []},
    ]

    this.history.forEach(h => {
      const updatedAt = new Date(h.updatedAt);
      const timeDiff = currentDate.getTime() - updatedAt.getTime();
      const daysDiff = timeDiff / (1000 * 3600 * 24);

      let category = historyCategoryEnum.last7days;

      if (daysDiff > 30) {
        category = historyCategoryEnum.older;
      } else if (daysDiff > 7) {
        category = historyCategoryEnum.last30days;
      }

      const group = this.groupedHistory.find(g => g.label === category);
      group.items.push(h);
    })
  }

  loadConstants() {
    this.settingsService.getConstants().subscribe({
      next: constants => {
        this.constants = constants;
      },
      error: err => {
        this.errorService.handleError(err, 'Could not get chat configuration.');
      }
    });
  }

  getMessagesTokenLength(currentMessage: string) {
    const messagesHistoryString = this.messages.map(m => m.message).join('');
    const tokens = this.tokenEncoding.encode(currentMessage + messagesHistoryString);
    return tokens.length;
  }

  updateCitationIndexes(citations: CitationFeModel[]) {
    if (citations) {
      citations.forEach((c, index) => {
        c.index = index + 1;
        c.used = false;
      });
    }
  }

  reloadSettingsSidebar() {
    if (!this.isAssistant()) {
      this.settingsSidebar.visible = false;
      setTimeout(() => {
        this.settingsSidebar.visible = true;
      }, 100);
    }
  }


  showFilesDialog() {
    this.displayFilesDialog = true;
  }

  isAssistant() {
    return this.chatType == ChatType.ASSISTANT;
  }

  updateFiles(files: File[]) {
    this.messageFileItems = files;
  }

  updateCodeInterpreterFiles(files: File[]) {
    this.messageCodeInterpreterFiles = files;
  }

  cancelUploadingFiles() {
    this.messageFileItems = [];
    this.messageCodeInterpreterFiles = [];
    this.displayFilesDialog = false;
  }

  showSendAgain(i: number) {
    return (this.aiResponseError && i === this.messages.length - 1) || (this.stopped && i === this.messages.length - 2);
  }

  isCurrentChatId(chat: ChatHistoryListModel | AssistantThreadListModel) {
    const idProperty = this.getChatIdProperty();
    return chat[idProperty].toString() === this._currentChatId;
  }

  getChatIdProperty() {
    return this.isAssistant() ? 'threadId' : 'chatId'
  }

  getAcceptedTypesFormatted(acceptedFiles: string): string {
    return FileUtils.getAcceptedTypesFormatted(acceptedFiles)
  }

  loadDaiseIndexes() {
    this.indexService.getIndexes()
      .subscribe({
        next: indexes => {
          this.availableIndexes = indexes;
          const defaultIndex = this.availableIndexes.find(i => i.default);
          if (defaultIndex) {
            this.daiseService.daiseIndex = defaultIndex;
          }
        },
        error: err => {
          this.errorService.handleError(err, 'Could not load indexes');
        }
      })
  }
}
