import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';
import { SafeUrl } from '@angular/platform-browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FileUtils } from '../../utils/file-utils/file-utils.component';
import { BehaviorSubject } from 'rxjs';
import { FileModel } from 'src/app/models/file-model';

export interface RequestPayload<T> {
  name: string;
  payload: T;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent implements OnInit {
  @Input() filesObservable: BehaviorSubject<FileModel[]>;

  @Input()
  get files(): File[] | undefined {
    return this.fileUpload?.files;
  }

  set files(value: File[] | undefined) {
    if (this.fileUpload && value) {
      this.fileUpload.files = value;
    }
  }

  @Input() uploadUrl? = '';
  @Input() multiple = false;
  @Input() maxFiles = 20;
  @Input() acceptedFileFormat = ''; //e.g. image/*, application/zip...

  @Input() fileParameterName!: string;
  @Input() autoUpload?: boolean;
  @Input() useFileName = true;
  @Input() showFiles = true;
  @Input() disableFileSelection = false;

  @Output() onUploadButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onProgress: EventEmitter<number> = new EventEmitter();
  @Output() onFileSelected: EventEmitter<File[]> = new EventEmitter();
  @Output() onFileRemoved: EventEmitter<unknown> = new EventEmitter();
  @Output() onCustomNameChanged: EventEmitter<{ file: File; customName: string }> = new EventEmitter();
  @ViewChild(FileUpload) fileUpload: FileUpload | undefined;

  protected readonly MAX_FILE_SIZE: number = 200000000;
  protected isUploading = false;
  protected totalSize: number = 0;
  protected totalSizePercent: number = 0;

  public customNames = new Map<File, string>();

  constructor(
    private readonly config: PrimeNGConfig,
    private readonly authService: OidcSecurityService,
    private readonly messageService: MessageService,
  ) {
    this.authService.getAccessToken();
  }

  ngOnInit(): void {
    this.fileParameterName ??= this.multiple ? 'files' : 'file';
    this.filesObservable.subscribe({
      next: (files) => {
        this.files = files;
      }
    })
  }

  protected onChooseButtonClick(callback: () => object) {
    if (callback) callback();
  }

  protected onRemoveFile(file: File, callback: () => object) {
    if (file) {
      this.files = this.files?.filter((f) => f !== file);
    }

    this.onFileRemoved.emit();
    if (callback) callback();
  }

  protected onSelectedFiles(event: FileSelectEvent) {
    this.customNames.clear();
    const files = event.files;
    Array.from(files)?.forEach((f) => {
      this.customNames?.set(f, f.name);
    });
    this.totalSize = this.files?.reduce((sum, current) => sum + current.size, 0) || 0;
    const filesWithoutDuplicities = Array.from(new Set([...this.files]));

    if ((filesWithoutDuplicities.length) > this.maxFiles) {
      filesWithoutDuplicities.splice(this.maxFiles);
      this.messageService.add({
        severity: 'error',
        summary: 'Max files exceeded',
        detail: `You can only upload ${this.maxFiles} files.`,
      })
    }

    this.onFileSelected.emit(filesWithoutDuplicities);
  }

  formatSize(bytes: number): string {
    return FileUtils.formatSize(bytes, this.config.translation.fileSizeTypes);
  }

  getObjectUrl(file: File): SafeUrl {
    if (!file) {
      return '';
    }
    return URL.createObjectURL(file);
  }

  customNameChanged(f: File, $event: Event) {
    const input = $event.target as HTMLInputElement;
    this.customNames.set(f, input.value);
    this.onCustomNameChanged.emit({ file: f, customName: input.value });
  }

  getFileIcon(filename: string | undefined): string {
    return FileUtils.getFileIcon(filename);
  }
}
