import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AssistantService,
  AssistantThreadModel,
  ChatHistoryListModel,
  ChatHistoryModel,
  ChatService
} from 'digiteq-ai-portal-client-lib';
import { ChatType } from '../../../models/chat-type';
import { MenuItem, MessageService } from 'primeng/api';
import { ErrorService } from '../../../services/error.service';
import { finalize } from 'rxjs';
import { ChatLocalHistoryService } from '../../../services/chat-local-history.service';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent implements OnInit{
  private _editingChatName = false
  get editingChatName(): boolean {
    return this._editingChatName;
  }

  set editingChatName(value: boolean) {
    this._editingChatName = value;

    const handleClickOutside = (event: MouseEvent) => {
      const input = document.getElementById('edit-chat-name');
      const isClickInside = input.contains(event.target as Node);

      if (!isClickInside) {
        handleEdit();
      }
    }

    const handleEnterKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        handleEdit();
      }
    }

    const handleEdit = () => {
      this._editingChatName = false;
      this.renameChatHistory();
      this.isHovered = false;
      this.historyMenuShown = false;
      document.body.removeEventListener('click', handleClickOutside);
      document.body.removeEventListener('keyup', handleEnterKeyUp);
    }

    if (value === true) {
      setTimeout(() => {
        document.body.addEventListener('click', handleClickOutside);
        document.body.addEventListener('keyup', handleEnterKeyUp);
      }, 0)
    }
  }

  get someHistoryItemLoading() {
    return this.historyService.isLoadingHistory;
  }

  @Input() chat: ChatHistoryListModel | AssistantThreadModel;
  @Input() chatType: ChatType;
  @Input() isCurrentChat: boolean;

  @Output() delete = new EventEmitter();
  @Output() rename = new EventEmitter();
  @Output() load = new EventEmitter<ChatHistoryModel | AssistantThreadModel>();

  historyMenuItems: MenuItem[];
  historyMenuShown = false;
  isHovered = false;
  chatName: string;
  loading = false;
  deleting = false;

  constructor(
    private chatService: ChatService,
    private assistantService: AssistantService,
    private errorService: ErrorService,
    private historyService: ChatLocalHistoryService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.historyMenuItems = [
      // {
      //   label: 'Share',
      //   icon: 'pi pi-upload',
      // },
      {
        id: 'rename',
        label: 'Rename',
        icon: 'pi pi-pencil',
        command: () => {
          this.editingChatName = true;
        }
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.deleteChatHistory();
        },
        disabled: this.deleting,
      },
    ];

    this.chatName = this.chat.name;
  }

  loadHistory() {
    const handleResponse = (chat: ChatHistoryModel | AssistantThreadModel) => {
      this.load.emit(chat);
    };

    const handleError = (err: any) => {
      this.errorService.handleError(err, 'Could not load the chat from history.');
    };

    const loading = (loading: boolean) => {
      this.loading = loading;
      this.historyService.isLoadingHistory = loading;
    }

    if (this.isCurrentChat || this.loading || this.someHistoryItemLoading) {
      return;
    }

    loading(true);

    if (this.chatType === ChatType.ASSISTANT) {
      this.assistantService.loadAssistantThread((this.chat as AssistantThreadModel).threadId)
        .pipe(finalize(() => {
          loading(false);
        }))
        .subscribe({
          next: handleResponse,
          error: handleError
      });
    } else {
      this.chatService.loadChat((this.chat as ChatHistoryModel).chatId)
        .pipe(finalize(() => {
          loading(false);
        }))
        .subscribe({
          next: handleResponse,
          error: handleError
      });
    }
  }

  deleteChatHistory() {
    const handleResponse = () => {
      this.delete.emit(id);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Chat deleted successfully.'
      })
    };

    const handleError = (err: any) => {
      this.errorService.handleError(err, 'Could not delete the chat from history.');
    };

    const handleDeleteButton = (disable: boolean) => {
      this.historyMenuItems.forEach(menuItem => {
        menuItem.disabled = disable;
      })
    }

    const id = this.chatType == ChatType.ASSISTANT ? (this.chat as AssistantThreadModel).threadId : (this.chat as ChatHistoryModel).chatId;
    handleDeleteButton(true);

    if (this.chatType == ChatType.ASSISTANT) {
      this.assistantService.deleteAssistantChat((this.chat as AssistantThreadModel).threadId).pipe(
        finalize(() => {
          handleDeleteButton(false);
        })
      ).subscribe({
        next: handleResponse,
        error: handleError
      });
    } else {
      this.chatService.deleteChat((this.chat as ChatHistoryModel).chatId).subscribe({
        next: handleResponse,
        error: handleError
      });
    }
  }

  renameChatHistory() {
    const id = this.chatType == ChatType.ASSISTANT ? (this.chat as AssistantThreadModel).threadId : (this.chat as ChatHistoryModel).chatId;

    const handleResponse = () => {
      this.rename.next({ id, newName: this.chatName });
    };

    const handleError = (err: any) => {
      this.errorService.handleError(err, 'Could not rename your chat.');
    };

    if (this.chatType == ChatType.ASSISTANT) {
      this.assistantService.renameAssistantChat((this.chat as AssistantThreadModel).threadId, { newName: this.chatName }).subscribe({
        next: handleResponse,
        error: handleError
      });
    } else {
      this.chatService.renameChat((this.chat as ChatHistoryModel).chatId, { newName: this.chatName }).subscribe({
        next: handleResponse,
        error: handleError
      });
    }
  }

   onChatHistoryMenuMouseLeave() {
    if (this.historyMenuShown) {
      return;
    }
    this.isHovered = false;
  }

  onChatHistoryMenuHide() {
    this.historyMenuShown = false;
    this.isHovered = false;
  }

}
