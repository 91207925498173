import { Component } from '@angular/core';
import { ChatType } from '../../models/chat-type';

@Component({
  selector: 'app-assistant-chat',
  templateUrl: './assistant-chat.component.html',
  styleUrl: './assistant-chat.component.scss'
})
export class AssistantChatComponent {
  chatType = ChatType;
}
