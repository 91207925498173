import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtils } from 'src/app/utils/form-utils/form-utils';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrl: './form-error-message.component.scss'
})
export class FormErrorMessageComponent {
  @Input() form: FormGroup;
  @Input() controlName: string;
  readonly FormUtils = FormUtils;
}
