import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SettingsService } from '../../services/settings.service';
import { AuthService, UserData } from '../../services/auth.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { HelpComponent } from '../../pages/help/help.component';
import { CompanyChatService } from '../../company-chat.service';
import { UserRole } from 'digiteq-ai-portal-client-lib';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userMenuItems: MenuItem[] | undefined;
  adminMenuItems: MenuItem[] | undefined;

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  };

  get currentUser(): UserData {
    return this.authService.currentUser;
  }

  get heading(): string {
    switch (this.router.url) {
      case '/chat': return this.gptVersion;
      case '/company-chat': return 'DAISE' + this.daiseService.daiseIndexName;
      case '/assistant-chat': return 'File Chat';
      default: return '';
    }
  }

  get gptVersion(): string {
    return this.settingsService.getGptVersionDisplayName();
  }

  get isAdmin(): boolean {
    return this.authService.isInRoles([UserRole.Admin])
  }

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private router: Router,
    private dialog: DialogService,
    private daiseService: CompanyChatService,
  ) {}

  ngOnInit() {
    this.userMenuItems = [
      {
        label: 'My issues',
        icon: 'pi pi-check-square',
        routerLink: 'work-items'
      },
      {
        separator:true
      },
      {
        label: 'Log out',
        icon: 'pi pi-sign-out',
        command: () => { this.logout()}
      },
    ];

    this.adminMenuItems = [
      {
        label: 'DAISE indexes',
        icon: 'pi pi-key',
        command: () => { this.showDaiseIndexesAdministration()   }
      },
    ];
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  toLandingPage() {
     this.router.navigate(['']);
  }

  showHelp() {
    this.dialog.open(HelpComponent, {
      modal: true,
      width: '75%',
    })
  }

  showDaiseIndexesAdministration() {
    void this.router.navigate(['company-chat-admin']);
  }

}
