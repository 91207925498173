<div class="flex-fill d-flex overflow-y-hidden">
  <div class="p-3 position-absolute">
      <div><p-button icon="fa-solid fa-list" [text]="true" severity="secondary" (onClick)="historyVisible = true"
                  pTooltip="Show chat history"/></div>
      <div><p-button icon="fa-regular fa-pen-to-square" [text]="true" severity="secondary" (onClick)="reset()"
                  pTooltip="New chat"/></div>
  </div>
  <div class="p-3 position-absolute" style="right: 0">
    <p-button *ngIf="chatType === chatTypeEnum.GENERIC" icon="pi pi-cog"  [rounded]="true" [text]="true" severity="secondary"
              (click)="settingsVisible = !settingsVisible" pTooltip="Settings"></p-button>
    <div *ngIf="chatType === chatTypeEnum.COMPANY && availableIndexes.length > 1" class="button-wrapper">
      <p-button icon="pi pi-cog" [rounded]="true" [text]="true" severity="secondary"  pTooltip="Settings" (click)="companyChatSettingsVisible = !companyChatSettingsVisible"></p-button>
    </div>
    <div *ngIf="chatType === chatTypeEnum.ASSISTANT" class="button-wrapper" >
      <p-button icon="pi pi-cog" [rounded]="true" [text]="true" severity="secondary" pTooltip="Settings" (click)="assistantSettingsVisible = !assistantSettingsVisible"></p-button>
    </div>
  </div>
  <div *ngIf="historyVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar #historySidebar id="history" styleClass="chat-sidebar" [(visible)]="historyVisible" position="left"
               [modal]="false">
      <ng-template pTemplate="header">
        <div class="flex-fill">
          <h4 class="text-center">Chat history</h4>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <ng-container *ngIf="history">
          <div class="mt-1">
            <p-button pRipple
                      icon="fa-regular fa-pen-to-square"
                      label="Start new chat"
                      [text]="true" severity="secondary"
                      (click)="reset()"></p-button>
          </div>
          <div *ngFor="let group of groupedHistory">
            <ng-container *ngIf="group.items.length">
              <div class="mt-3 history-order-label">{{group.label}}</div>
              <div *ngFor="let chat of group.items">
                <app-history-item
                  [chat]="chat"
                  [chatType]="chatType"
                  [isCurrentChat]="isCurrentChatId(chat)"
                  (load)="onChatHistoryItemLoad($event)"
                  (rename)="onChatRename($event)"
                  (delete)="onChatHistoryItemDelete($event)"
                ></app-history-item>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="!history.length">
          Nothing to show...
        </div>
        <div *ngIf="loadingChatHistory" class="d-flex">
          <div class="m-auto">
            <p-progressSpinner></p-progressSpinner>
          </div>
        </div>
      </ng-template>
    </p-sidebar>
  </div>

  <div class="flex-fill d-flex flex-column overflow-y-hidden">
      <div *ngIf="showWelcomeMsg" class="page-container">
        <div class="container">
          <h1 class="text-center">Start chatting</h1>
          <p class="text-center">
            {{description}}</p>
          <p class="text-center">
            We use our own separate <strong>GPT</strong> models, which run securely in Microsoft Azure. The application
            allows you to chat about topics and information up to classification level <strong>confidential.</strong></p>
          <p *ngIf="isAssistant()" class="text-center">
            Please upload your file(s) either using the toolbar below to attach them to a single prompt, or through the
            settings on the right side to attach them to the entire thread.
          </p>
        </div>

      </div>

      <div #chatContainer class="overflow-y-auto flex-fill page-container">
        <div id="chat-container" class="container">
          <div class="flex-column flex-fill d-flex mx-2">
            <ng-container *ngFor="let chatMessage of messages; let i = index">
              <div class="w-100 d-flex" [ngStyle]="{'justify-content' : chatMessage.role !== role.Assistant ? 'end' : ''}">
                <div [id]="'chat-msg-' + i" class="border-1 chat-msg"
                     [ngClass]="{'ai-msg' : chatMessage.role === role.Assistant, 'err-msg' : aiResponseError && i === messages.length - 1}">
                  <ng-container *ngIf="showSendAgain(i); else message">
                    <div class="d-flex" [ngStyle]="{'justify-content': aiResponseError ? 'space-between' : 'end'}">
                      <i *ngIf="aiResponseError" class="fa-solid fa-triangle-exclamation" style="color: red"></i>
                      <i *ngIf="!promptViolation" class="fa-solid fa-arrow-rotate-right"
                         [ngClass]="{'send-again-error': aiResponseError, 'send-again-stopped blue-400': stopped }"
                         (click)="send(true)" pTooltip="Send again"></i>
                    </div>
                    <div [innerHTML]="getFormattedTextareaValue(chatMessage.message)"></div>
                  </ng-container>
                  <ng-template #message>
                    <ng-container *ngIf="chatMessage.role === role.Assistant">
                      <div class="d-flex">
                        <div class="markdown-container">
                          <markdown katex [katexOptions]="katexOptions" mermaid clipboard [data]="(chatMessage.message)"></markdown>
                        </div>
                        <div *ngIf="!aiTypingResponse || chatMessage.completed" class="ms-2">
                          <div class="d-flex justify-content-end">
                            <i class="fa-regular fa-copy" style="color: var(--primary-color)"
                               (click)="copyToClipboard(chatMessage.message)" pTooltip="Copy"></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="chatMessage.role === role.Assistant && hasUsedCitation(chatMessage)"
                         class="mt-2 d-flex flex-wrap">
                      <div class="fw-bold me-1 d-flex align-items-center">Citations:</div>
                      <ng-container *ngFor="let cit of chatMessage.citations">
                        <div *ngIf="cit.used" class="citation">
                          <a [href]="cit.url" target="_blank">{{cit.index + ". " + cit.title | slice:0:42}} {{ cit.title.length > 42 ? '...' : '' }}</a>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="chatMessage.role !== role.Assistant"
                         [innerHTML]="getFormattedTextareaValue(chatMessage.message)">
                    </div>
                    <div *ngIf="chatMessage.files?.length > 0" class="mt-2 d-flex flex-wrap">
                      <div class="fw-bold me-1 d-flex align-items-center">Files:</div>
                      <ng-container *ngFor="let file of chatMessage.files; let index = index">
                        <div class="citation">
                          {{index+1 + ". " + file | slice:0:42}} {{ file.length > 42 ? '...' : '' }}
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="chatMessage.codeFiles?.length > 0" class="mt-2 d-flex flex-wrap">
                      <div class="fw-bold me-1 d-flex align-items-center">Code interpreters:</div>
                      <ng-container *ngFor="let file of chatMessage.codeFiles; let index = index">
                        <div class="citation">
                          {{index+1 + ". " + file | slice:0:42}} {{ file.length > 42 ? '...' : '' }}
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="w-100 d-flex"
                   *ngIf="aiTypingResponse && chatMessage.role !== role.Assistant && i === messages.length - 1">
                <div class="border-1 chat-msg ai-msg">
                  <span class="typing-indicator"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf="messageForm" class="page-container mb-3 mt-3">
        <div class="container">
          <div class="text-container">
            <div class="d-flex">
              <div class="p-inputgroup">
                <textarea #textArea id="chat-textarea" class="text-area" pInputTextarea [formControl]="messageForm"
                      (keydown.enter)="$event.preventDefault(); send()" [rows]="1"
                      placeholder="Type your question..."></textarea>
                <div *ngIf="isAssistant()"
                  class="button-with-indicator">
                  <div class="button-wrapper">
                    <p-button icon="pi pi-paperclip" (click)="showFilesDialog()" [pTooltip]="'Attach files to prompt'" [text]="true"></p-button>
<!--                    TODO: use   p-overlaybadge from PrimeNG 18-->
                    <span *ngIf="messageFileItems.length + messageCodeInterpreterFiles.length > 0" class="file-count-indicator">{{ messageFileItems.length + messageCodeInterpreterFiles.length }}</span>
                  </div>
                </div>
                <p-button [icon]="aiTypingResponse ?'fa-regular fa-hand' :'fa-regular fa-paper-plane' "
                          (click)="handleActionButton()"
                          [disabled]="!aiTypingResponse && !messageForm.valid"
                          [pTooltip]="aiTypingResponse ? 'Stop generating' : 'Send message'"></p-button>
              </div>
            </div>
            <div *ngIf="hasInputValueErrors"><small class="p-error">Please enter at least 3 non-whitespace
              characters</small></div>
          </div>
          <p class="text-center">
            Answers generated by AI may be incorrect.
            <a href="https://dqwiki.skoda.vwgroup.com/display/AIPortal/Digiteq+AI+portal" target="_blank">About AI Portal</a>
          </p>
        </div>
      </div>

      <p-dialog header="Prompt Files" [(visible)]="displayFilesDialog" [modal]="true" [style]="{width: '65%'}">
        <div class="d-flex mb-3">
          <i class="pi pi-info-circle me-1"></i>
          <div>Selected files are attached to single prompt (message). To attach files to whole context of your conversation, click on Settings click on upper right corner of the chat.</div>
        </div>
        <app-chat-assistant-files title="File search" [files]="messageFileItems" (onFilesChange)="updateFiles($event)"
                                  [acceptedFiles]="appConstants.ACCEPTED_FILE_FORMATS_FILES"
        ></app-chat-assistant-files>
        <app-chat-assistant-files title="Code interpreters" [files]="messageCodeInterpreterFiles" (onFilesChange)="updateCodeInterpreterFiles($event)"
                                  [acceptedFiles]="appConstants.ACCEPTED_FILE_FORMATS_CODE_INTERPRETERS"></app-chat-assistant-files>
        <div class="d-flex justify-content-end">
          <div>
            <p-button label="Cancel" severity="secondary" icon="pi pi-times" (click)="cancelUploadingFiles()"/>
            <p-button label="Confirm" icon="pi pi-check" (click)="displayFilesDialog = false" />
          </div>
        </div>

      </p-dialog>
   </div>

  <div *ngIf="settingsVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar #settingsSidebar id="settings" styleClass="chat-sidebar" [(visible)]="settingsVisible" position="right"
               [modal]="false">
      <ng-template pTemplate="header">
          <div class="flex-fill">
            <h4 class="text-center">Settings</h4>
          </div>
      </ng-template>
      <app-settings></app-settings>
    </p-sidebar>
  </div>

  <div *ngIf="assistantSettingsVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar #assistantSettingsSidebar id="assistantSettings" styleClass="chat-sidebar" [(visible)]="assistantSettingsVisible" position="right"
               [modal]="false">
      <ng-template pTemplate="header">
        <p-button icon="pi pi-info-circle" class="ms-0" [rounded]="true" [text]="true" severity="secondary" (onClick)="op.toggle($event)" />
        <div class="flex-fill">
          <h4 class="text-center">Thread files</h4>
        </div>
        <div>
          <p-overlayPanel #op>
            <p class="mt-0">
              Selected files are added to a context of the whole conversation (thread).
            </p>
            <div>
              <strong>Search files</strong>
            </div>
            <p class="mt-0">
              Supported file types: {{getAcceptedTypesFormatted(appConstants.ACCEPTED_FILE_FORMATS_FILES)}}
            </p>
            <div>
              <strong>Code interpreters</strong>
            </div>
            <p class="mt-0">
              Supported file types: {{getAcceptedTypesFormatted(appConstants.ACCEPTED_FILE_FORMATS_CODE_INTERPRETERS)}}
            </p>
            <p class="mt-0">
              Max. size of file: 200 MB, all files together: 512 MB (search files and code interpreters have its own limit). Max. number of files: 20.
            </p>
          </p-overlayPanel>
        </div>
      </ng-template>
      <app-assistant-settings></app-assistant-settings>
    </p-sidebar>
  </div>

  <div *ngIf="companyChatSettingsVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar id="companyChatSettings" styleClass="chat-sidebar" [(visible)]="companyChatSettingsVisible" position="right"
               [modal]="false">
      <ng-template pTemplate="header">
        <div class="flex-fill">
          <h4 class="text-center">Settings</h4>
        </div>
      </ng-template>
      <app-company-chat-settings [availableIndexes]="availableIndexes"></app-company-chat-settings>
    </p-sidebar>
  </div>
</div>

<p-toast />
<p-confirmDialog />

