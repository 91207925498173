import { Component, OnInit } from '@angular/core';
import { AiPortalItem } from '../../models/ai-portal-item';
import { AuthService } from '../../services/auth.service';
import { IndexService, UserRole } from 'digiteq-ai-portal-client-lib';
import { ErrorService } from '../../services/error.service';

const AI_PORTAL_ITEMS: AiPortalItem[] = [
  {id: 0, title: 'AI Chat', description: 'Boost your productivity with AI assistance', icon: 'fa-regular fa-comments', routerLink: 'chat', enabled: true, canAccess: [UserRole.Chat]},
  {id: 1, title: 'DAISE', description: 'Get quick answers from company data', icon: 'fa-brands fa-searchengin', routerLink: 'company-chat', enabled: true, canAccess:[UserRole.CompanyChat]},
  {id: 2, title: 'File Chat', description: 'Chat and work with your files easily', icon: 'filechat-icon-enabled', routerLink: 'assistant-chat', enabled: true, canAccess: [UserRole.AiAssistant]}
];

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit{
  aiPortalItems: AiPortalItem[] = AI_PORTAL_ITEMS;
  isAuthenticated = false;

   constructor(
    private authService: AuthService,
    private indexService: IndexService,
    private errorService: ErrorService,
   ) {
  }

  ngOnInit() {
     this.authService.isAuthenticated$.subscribe(isAuth => {
       this.isAuthenticated = isAuth;
     })

    this.authService.currentUser$.subscribe(user => {
      if (user) {
        this.aiPortalItems.forEach(item => {
          this.checkAccessRights(item.title, item.canAccess);
        })
        this.checkDaiseIndexes();
      }
    })
   }

  private checkAccessRights(app: string, roles: UserRole[]) {
    const index = this.aiPortalItems.findIndex(api => api.title === app);
    const chat = this.aiPortalItems[index];
    if (!this.authService.isInRoles(roles)) {
      chat.enabled = false;
      if (app === 'File Chat') {
        this.aiPortalItems[2].icon = 'filechat-icon-disabled'
      }
      chat.routerLink = '';
      chat.description = 'You don\'t have right for this app...';
    } else {
      chat.enabled = true;
      if (app === 'File Chat') {
        this.aiPortalItems[2].icon = 'filechat-icon-enabled'
      }
      chat.routerLink = AI_PORTAL_ITEMS[index].routerLink;
      chat.description = AI_PORTAL_ITEMS[index].description;
    }
  }

  private checkDaiseIndexes() {
     this.indexService.getIndexes().subscribe({
        next: indexes => {
          if (indexes.length === 0) {
            this.aiPortalItems[1].enabled = false;
            this.aiPortalItems[1].description = 'No indexes found...';
          }
        },
        error: () => {
          this.aiPortalItems[1].enabled = false;
          this.errorService.handleError( 'Error getting DAISE indexes', 'DAISE is disabled');
        },
     });
  }

}
