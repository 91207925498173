import { Injectable } from '@angular/core';
import { FeMessageModel } from '../models/fe-message.model';

const SESSION_STORAGE_HISTORY = 'dq-chat-history'

@Injectable({
  providedIn: 'root'
})
export class ChatLocalHistoryService {
  private _isLoadingHistory = false;
  get isLoadingHistory(): boolean {
    return this._isLoadingHistory;
  }

  set isLoadingHistory(value: boolean) {
    this._isLoadingHistory = value;
  }


  getHistory(): FeMessageModel[] {
    const storageSettings = sessionStorage.getItem(SESSION_STORAGE_HISTORY);
    return JSON.parse(storageSettings)
  }

  setHistory(value: FeMessageModel[]) {
    sessionStorage.setItem(SESSION_STORAGE_HISTORY, JSON.stringify(value));
  }


}
