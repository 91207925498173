import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkItemsModule } from './pages/work-items/work-items.module';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { userRoleGuard } from './guards/user-role.guard';
import { UserRole } from 'digiteq-ai-portal-client-lib';
import { GenericChatComponent } from './pages/public-chat/generic-chat.component';
import { CompanyChatComponent } from './pages/company-chat/company-chat.component';
import { AssistantChatComponent } from './pages/assistant-chat/assistant-chat.component';
import { DaiseAdminComponent } from './pages/daise-admin/daise-admin.component';
import { daiseGuard } from './guards/daise.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'home',
  },
  {
    path: 'work-items',
    loadChildren: () => WorkItemsModule,
  },
  {
    path: 'home',
    component: LandingPageComponent,
  },
  {
    path: 'chat',
    component: GenericChatComponent,
    canActivate: [userRoleGuard],
    data: {
      roles: [UserRole.Chat]
    }
  },
  {
    path: 'company-chat',
    component: CompanyChatComponent,
    canActivate: [userRoleGuard, daiseGuard],
    data: {
      roles: [UserRole.CompanyChat]
    }
  },
  {
    path: 'assistant-chat',
    component: AssistantChatComponent,
    canActivate: [userRoleGuard],
    data: {
      roles: [UserRole.AiAssistant]
    }
  },
  {
    path: 'company-chat-admin',
    component: DaiseAdminComponent,
    canActivate: [userRoleGuard],
    data: {
      roles: [UserRole.Admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
