<div class="container">
  <h3 id="en">EN</h3>
  <h2 id="help-en">Welcome to the AI Portal</h2>
  <p>This portal offers three specialized applications, AI Chat, DAISE and File Chat, to enhance your productivity. See below for important information about their functioning and your rights:</p>
  <ol>
    <li>
      <h3>Identification as an AI system</h3>
      <ul>
        <li>Be aware that while using our applications, you communicate with an artificial intelligence, not with a human.</li>
      </ul>
    </li>
    <li>
      <h3>Purpose of the application</h3>
      <ul>
        <li><b>AI Chat:</b> Designated for work purposes, enter queries up to the Confidential level. Not intended for personal use.</li>
        <li><b>DAISE:</b> Helps searching in internal documents via chat, enter queries up to the Confidential level.
          The answer comes with a link to the internal document where the information was taken from.
          Not intended to be a substitute for legal or medical advice.</li>
        <li><b>File Chat:</b> Allows you to analyze your own files or use it as code interpreter, i.e. for tasks
          unsuitable for language models like counting. Code interpreter provides access to a sandboxed
          Python environment that can be used to allow the model to test and execute code. The application is based on GPT-4o-mini model.</li>
      </ul>
    </li>
    <li>
      <h3>Abilities and limitations</h3>
      <ul>
        <li>Our systems generate texts based on prompts but may contain inaccuracies and are not always up to date. We recommend verifying important information from other sources.</li>
      </ul>
    </li>
    <li>
      <h3>Security and privacy policy</h3>
      <ul>
        <li>Your data is processed in compliance with GDPR. They are not used to train AI models. You have the right to access your data and to their deletion.</li>
      </ul>
    </li>
    <li>
      <h3>How to interact with the applications</h3>
      <ul>
        <li>For efficient use, enter clear and specific questions. The system may not be used in any manner that is unlawful or harmful.</li>
      </ul>
    </li>
    <li>
      <h3>Output explanation</h3>
      <ul>
        <li>The answers are generated based on training data and probabilistic models and are not intended to be a substitute for professional consulting.</li>
      </ul>
    </li>
  </ol>
  <br>
  <br>
  <h3 id="cz">CZ</h3>
  <h2 id="help-cz">Vítejte na AI Portálu</h2>
  <p>Tento portál nabízí tři specializované aplikace, AI Chat, DAISE a File Chat, které slouží k podpoře vaší pracovní efektivity. Níže naleznete důležité informace o jejich fungování a vašich právech:</p>
  <ol>
    <li>
      <h3>Identifikace jako AI systém</h3>
      <ul>
        <li>Upozorňujeme, že při používání našich aplikací komunikujete s umělou inteligencí, nikoliv s člověkem.</li>
      </ul>
    </li>
    <li>
      <h3>Účel aplikace</h3>
      <ul>
        <li><b>AI Chat:</b> Určen pro pracovní účely, vložené dotazy mohou být až do úrovně Confidental. Není určený pro osobní použití.</li>
        <li><b>DAISE:</b> Pomáhá při vyhledávání v interních dokumentech pomocí chatu, vložené  dotazy mohou být až do úrovně
           Confidental. U odpovědi je uveden odkaz na interní dokument, z kterého informace pochází. Není určený pro právní
          nebo lékařské poradenství.</li>
        <li><b>File Chat:</b> Umožňuje analyzovat Vaše vlastní soubory nebo ho používat jako interpretr kódu, tj. pro
          úkoly nevhodné pro jazykové modely, jako je počítání. Interpretr kódu poskytuje přístup k izolovanému prostředí
          Python, které lze použít k tomu, aby model mohl testovat a spouštět kód. Aplikace je založena na modelu GPT-4o-mini. </li>
      </ul>
    </li>
    <li>
      <h3>Schopnosti a omezení</h3>
      <ul>
        <li>Naše systémy generují texty na základě vstupů, ale mohou obsahovat nepřesnosti a nejsou vždy aktuální. Doporučujeme ověřit důležité informace z jiných zdrojů.</li>
      </ul>
    </li>
    <li>
      <h3>Bezpečnost a ochrana osobních údajů</h3>
      <ul>
        <li>Vaše data jsou zpracovávána v souladu s GDPR. Nejsou používána k trénování AI modelů. Máte právo na přístup k vašim datům a jejich vymazání.</li>
      </ul>
    </li>
    <li>
      <h3>Způsob interakce</h3>
      <ul>
        <li>Pro efektivní využití zadávejte jasné a konkrétní otázky. Systém nesmí být použit pro nezákonné nebo škodlivé účely.</li>
      </ul>
    </li>
    <li>
      <h3>Vysvětlení výstupů</h3>
      <ul>
        <li>Odpovědi jsou generovány na základě tréninkových dat a pravděpodobnostních modelů a nemají charakter profesionálního poradenství.</li>
      </ul>
    </li>
  </ol>
</div>
