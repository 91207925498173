<div class="field col">
  <label for="index">Available Indexes</label>
  <div>
    <p-dropdown id="index" class="mt-1" styleClass="w-100"  [options]="availableIndexes" [(ngModel)]="index"
                optionLabel="name" placeholder="Default index" appendTo="body">
      <ng-template pTemplate="selectedItem">
        {{ index.name }}
        <span *ngIf="index.default">(default)</span>
      </ng-template>
      <ng-template let-index pTemplate="item">
        {{ index.name }}
        <span *ngIf="index.default">(default)</span>
      </ng-template>
    </p-dropdown>
  </div>
</div>
