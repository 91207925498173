import { Injectable } from '@angular/core';
import { FileModel } from '../models/file-model';

@Injectable({
  providedIn: 'root'
})
export class AiAssistantService {
  threadSearchFiles: FileModel[] = [];
  threadCodeInterpreterFiles: FileModel[] = [];
  threadId?: number;
}
