import { Injectable } from '@angular/core';
import { IndexAdminModel } from 'digiteq-ai-portal-client-lib';

@Injectable({
  providedIn: 'root'
})
export class CompanyChatService {
  private _daiseIndex?: IndexAdminModel;
  get daiseIndex(): IndexAdminModel {
    const localStorageDasieIndex = localStorage.getItem('daiseIndex');
    if (localStorageDasieIndex) {
      this._daiseIndex = JSON.parse(localStorageDasieIndex);
    }
    return this._daiseIndex;
  }

  set daiseIndex(value: IndexAdminModel) {
    this._daiseIndex = value;
    localStorage.setItem('daiseIndex', JSON.stringify(value));
  }

  get daiseIndexName(): string {
    this._daiseIndex = this.daiseIndex;
    if (this._daiseIndex?.name) {
      return ' - ' + this._daiseIndex.name;
    }
    return '';
  }
}
