<div class="mb-3">
  <div class="row bordered-container">
    <div><strong>{{title}}
      @if (!files?.length) {
        <span>(max. 20 files)</span>
      } @else {
        ({{files?.length}} / {{maxNumberOfFiles}} files | {{formatSize(filesSizeSum)}})
      }
    </strong></div>
    <div class="col-6">
      <p-table *ngIf="files.length" [value]="files" [scrollable]="true"
               scrollHeight="200px">
        <ng-template pTemplate="body" let-document let-index="rowIndex">
          <tr>
            <td><i [class]="getFileIcon(document.name)" style="font-size: 1.25rem"></i></td>
            <td class="font-semibold text-truncate" style="max-width: 20rem;">{{ document?.name }}</td>
            <td>{{ formatSize(document?.size) }}</td>
            <td class="delete-icon-cell">
              <p-button icon="pi pi-trash"
                        styleClass="width-fix"
                        (onClick)="deleteFile(index)"
                        [rounded]="true"
                        [text]="true"
                        severity="secondary"/>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="empty-table" *ngIf="!files.length">
          <i class="fa-solid fa-file-circle-xmark"></i>
          No files selected yet...
      </div>
    </div>
    <div class="col-6">
      <app-file-upload
        [files]="files"
        [multiple]="true"
        [maxFiles]="maxNumberOfFiles"
        [autoUpload]="false"
        [showFiles]="false"
        [acceptedFileFormat]="acceptedFiles"
        (onFileSelected)="handleFileSelect($event)"
      ></app-file-upload>
    </div>
    <div class="mt-3" style="font-size: 0.9rem">
      <div>Supported file types: {{acceptedTypesFormatted}}</div>
      <div>Max. size of file: 200 MB, all files together: 512 MB</div>
    </div>
  </div>
</div>
